#footer{ background: #103C67; border-top: 3px solid #617184; 
	// Для телефонов
	@include respond-to(handhelds) { height: 250px; };
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) { height: 220px; };
	// Для маленьких планшетов
	@include respond-to(small-tablets) { height: 220px; };
	// Для планшетов
	@include respond-to(tablets) { height: 180px; };
	// Для экранов
	@include respond-to(display) { height: 190px; };


	.padding{ padding: 25px 1%;


		// Для телефонов
		@include respond-to(handhelds) {  
			.footer-logo{ position: absolute; left:1%; top: 5px; width: 180px; height: 96px;
				img{max-width: 100%; max-height: 100%}
			}//.footer-logo

			.wrap-footer-box{ position: absolute; top:90px; height: 140px; width: 97.9%; outline: 0px solid red;
				.phone{text-align: center; outline: 0px solid;
					img{ width: 30px; height: 20px; margin: 5px auto; display: none;}
					p{text-align: center; color: #fff; font-family: 'Calibri',sans-serif; font-size: 14px; margin: 4px 8px; display:inline-block;
						span{position: absolute; left: 10px; font-size: 13px; display: none}
				}
				}//.phone

				.address{ text-align: center; 
					img{ width: 30px; height: 20px; margin: 5px auto;  display: none;}
					p{ font-size: 14px; font-family: 'Calibri', sans-serif; color:#fff; margin-bottom: 8px;
					span{display:inline-block; text-align: left; font-size: 12px; margin-right: 10px;}
				}
				}//.address

				.mail{ outline: 0px solid;
					img{ width: 30px; height: 20px; margin: 5px auto;  display: none;}
					p{text-align: center; font-size: 14px; font-family: 'Calibri', sans-serif; color:#fff; margin: 2px; 
					span{ font-size: 12px;  margin-right: 10px; display: inline-block;}
				}
				}//.mail
			}//.wrap-footer-box

			.buy-ukr{position: absolute; right:1%; top: 5px; width: 70px; height: 141px;
					img{max-width: 100%; max-height: 100%}
				}//.buy-ukr


		 };// Для телефонов
 

		// Для телефонов с широким экраном
		@include respond-to(wide-handhelds) {  
			.footer-logo{ position: absolute; left:1%; top: 5px; width: 200px; height: 96px;
				img{max-width: 100%; max-height: 100%}
			}//.footer-logo

			.wrap-footer-box{ position: absolute; top:90px; height: 105px; width: 97.9%; outline: 0px solid red;
				.phone{text-align: center; outline: 0px solid;
					img{ width: 30px; height: 20px; margin: 5px auto; display: none;}
					p{text-align: center; color: #fff; font-family: 'Calibri',sans-serif; font-size: 14px; margin: 4px 8px; display:inline-block;
						span{position: absolute; left: 10px; font-size: 13px; display: none}
				}
				}//.phone

				.address{ text-align: center; 
					img{ width: 30px; height: 20px; margin: 5px auto;  display: none;}
					p{ font-size: 14px; font-family: 'Calibri', sans-serif; color:#fff; margin-bottom: 8px;
					span{display:inline-block; text-align: left; font-size: 12px; margin-right: 10px;}
				}
				}//.address

				.mail{ outline: 0px solid;
					img{ width: 30px; height: 20px; margin: 5px auto;  display: none;}
					p{text-align: center; font-size: 14px; font-family: 'Calibri', sans-serif; color:#fff; margin: 2px; 
					span{ font-size: 12px;  margin-right: 10px; display: inline-block;}
				}
				}//.mail
			}//.wrap-footer-box

			.buy-ukr{position: absolute; right:1%; top: 5px; width: 80px; height: 141px;
					img{max-width: 100%; max-height: 100%}
				}//.buy-ukr
		};// Для телефонов с широким экраном


		// Для маленьких планшетов
		@include respond-to(small-tablets) {  
		.footer-logo{ position: absolute; left:1%; top: 10px; width: 220px; height: 96px;
			img{max-width: 100%; max-height: 100%}
		}//.footer-logo

		.wrap-footer-box{ position: absolute; top: 90px; height: 120px; width: 97.9%; outline: 0px solid red;
			.contact{ @include pod-block-adaptive; padding: 1%; width: 30.7%;}
			.phone{ height: 110px; text-align: center; outline: 0px solid;
				img{ width: 30px; height: 20px; margin: 0; }
				p{text-align: center; color: #fff; font-family: 'Calibri',sans-serif; font-size: 14px; margin: 6px 0; padding-left: 0; position: relative;
				span{position: absolute; left: 10px; font-size: 13px; display: none}
			}
			}//.phone

			.address{ height:110px;  text-align: center; border-left:1px solid #617184 ; border-right:1px solid #617184;
				img{ width: 30px; height: 20px; margin: 0; }
				p{text-align: left; font-size: 13px; font-family: 'Calibri', sans-serif; color:#fff; margin-bottom: 8px;
				span{display:block; text-align: left; font-size: 12px; margin: 0;}
			}
			}//.address

			.mail{ height: 110px; text-align: center; outline: 0px solid;
				img{ width: 30px; height: 20px; margin: 0; }
				p{text-align: left; font-size: 14px; font-family: 'Calibri', sans-serif; color:#fff; margin-bottom: 22px; padding-left: 10px;
				span{ font-size: 12px; margin: 0; margin-right: 10px; display: block;}
			}
			}//.mail
		}//.wrap-footer-box

		.buy-ukr{position: absolute; right:1%; top: 10px; width: 80px; height: 141px;
				img{max-width: 100%; max-height: 100%}
			}//.buy-ukr
		};// Для маленьких планшетов



		// Для планшетов 
		@include respond-to(tablets) { 
			.footer-logo{ position: absolute; left:1%; top: 40px; width: 20%; height: 96px;
				img{max-width: 100%; max-height: 100%}
			}//.footer-logo

			.wrap-footer-box{ position: relative; height: 130px; width: 550px; float: right; margin-right: 80px; outline: 0px solid red;
				.phone{position: absolute; left:1px; top: 0; width: 250px; height: 130px; text-align: center; outline: 0px solid;
					img{ width: 30px; height: 20px; margin: 5px auto; }
					p{text-align: center; color: #fff; font-family: 'Calibri',sans-serif; font-size: 14px; margin: 8px 0; padding-left: 0; position: relative;
						span{position: absolute; left: 10px; font-size: 13px; display: none}
				}
				}//.phone

				.address{position: absolute; right: 140px; top: 0; width: 180px; height:130px; padding: 0 7px; text-align: center; border-left:1px solid #617184 ; border-right:1px solid #617184;
					img{ width: 30px; height: 20px; margin: 5px auto; }
					p{text-align: left; font-size: 14px; font-family: 'Calibri', sans-serif; color:#fff; margin-bottom: 8px;
					span{display:block; text-align: left; font-size: 12px; margin: 0;}
				}
				}//.address

				.mail{position:absolute; right: 2px; top: 0; height: 130px; width: 140px; text-align: center; outline: 0px solid;
					img{ width: 30px; height: 20px; margin: 5px auto; }
					p{text-align: left; font-size: 14px; font-family: 'Calibri', sans-serif; color:#fff; margin-bottom: 22px; padding-left: 10px;
					span{ font-size: 12px; margin: 0; margin-right: 10px; display: block;}
				}
				}//.mail
			}//.wrap-footer-box

			.buy-ukr{position: absolute; right:1%; top: 40px; width: 80px; height: 141px;
					img{max-width: 100%; max-height: 100%}
				}//.buy-ukr

		};// Для планшетов 


		// Для экранов
		@include respond-to(display) { 

		.footer-logo{ position: absolute; left:1%; top: 20px; width: 263px; height: 96px;
			img{max-width: 100%; max-height: 100%}
		}//.footer-logo

		.wrap-footer-box{ position: relative; height: 150px; width: 550px; float: right; margin-right: 150px; outline: 0px solid red;
			.phone{position: absolute; left:1px; top: 0; width: 250px; height: 150px; text-align: center; outline: 0px solid;
				img{ width: 30px; height: 20px; margin: 5px auto; }
				p{text-align: left; color: #fff; font-family: 'Calibri',sans-serif; font-size: 14px; margin: 10px 0; padding-left: 50px; position: relative;
					span{position: absolute; left: 10px; font-size: 13px;}
			}
			}//.phone

			.address{position: absolute; right: 188px; top: 0; width: 173px; height:150px; padding: 0 5px; text-align: center; border-left:1px solid #617184 ; border-right:1px solid #617184;
				img{ width: 30px; height: 20px; margin: 5px auto; }
				p{text-align: right; font-size: 14px; font-family: 'Calibri', sans-serif; color:#fff;
				span{display:block; text-align: left; font-size: 12px; margin: 5px 0 5px 0;}
			}
			}//.address

			.mail{position:absolute; right: 10px; top: 0; height: 150px; width: 170px; text-align: center;
				img{ width: 30px; height: 20px; margin: 5px auto; }
				p{text-align: left; font-size: 14px; font-family: 'Calibri', sans-serif; color:#fff; margin:10px 0;
				span{ font-size: 12px; margin: 5px 0 10px 0; margin-right: 10px;}
			}
			}//.mail
		}//.wrap-footer-box

		.buy-ukr{position: absolute; right:1%; top: 20px; width: 146px; height: 141px;
				img{max-width: 100%; max-height: 100%}
			}//.buy-ukr
		
		};// Для экранов

	}//.padding
}//#footer