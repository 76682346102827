#header{
	min-height: 170px;

	// Для телефонов
	@include respond-to(handhelds) { height: 210px;  }
	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) { height: 175px;  }
	// Для маленьких планшетов
	@include respond-to(small-tablets) { height: 170px;  }
	// Для планшетов
	@include respond-to(tablets) { height: 140px;  }
	// Для экранов
	@include respond-to(display) { }

.pod-block{
	// @include pod-block-100;

	background: #ffffff;
	

	// Для телефонов
	@include respond-to(handhelds) {  
	.logo{ position: absolute; top: 8px; left: 0px; height: 125px; width: 210px;	
		img{ max-width: 100%; max-height: 100%; outline: 0px solid;}
	}//.logo

	.phone-block{ position: absolute; right: 1%; top: 96px; width: 98%; padding-top: 0;  outline: 0px solid;
		img{ float: left;  width: 35px; height: 25px; display: none;}
		.phone{ 
			height: 40px; width: 49%; display: inline-block;	
				p{ margin:5px 0; padding-left: 0; font-size: 14px; text-align: center; font-family: $gothic, sans-serif; color: #003663;
					img{height:16px; width: 18px; margin-right: 5px; float: none; vertical-align: middle; display: inline-block;}
				}
		}//.phone
	}//phone-block

	.adres{ position: absolute; right: 1%; top: 145px; padding-top: 0px; width: 98%; outline: 0px solid;
		img{ float: left; width: 35px; height: 25px; display: none;}
		p{ margin: 4px  0; padding-left: 0; font-size: 14px; text-align: center; font-family: $gothic, sans-serif; color: #003663; 
			span{ font-size: 14px; color: #000; margin-right: 5px;}
		}
	}//.adres

	.language{ position: absolute; right: 1%; top: 0; outline: 0px solid;
		ul li{margin: 5px 0;
			a{ display: block; padding: 6px 15px; border-radius: 4px; text-decoration: none; text-align: center; font-size: 13px; font-family: $gothic, Arial,sans-serif; font-weight: bold; color:#fff; background: #DCDCDC;
				&:hover{ background: #0476C3; }	
			}
			a.active{ background: #0476C3; text-transform: uppercase; }
		}
	}//.language
	}// Для телефонов

	// Для телефонов с широким экраном
	@include respond-to(wide-handhelds) {  
		.logo{ position: absolute; top: 11px; left: 0px; height: 125px; width: 250px;	
			img{ max-width: 100%; max-height: 100%; outline: 0px solid;}
		}//.logo

		.phone-block{ position: absolute; right: 10%; top: 10px; width: 37%; padding-top: 0;  outline: 0px solid;
			img{ float: left;  width: 35px; height: 25px; display: none;}
			.phone{ 
				height: 40px; width: auto; display: block;	
					p{ margin:5px 0; padding-left: 10px; font-size: 14px; text-align: center; font-family: $gothic, sans-serif; color: #003663;
						img{height:16px; width: 18px; margin-right: 5px; float: none; vertical-align: middle; display: inline-block;}
					}
			}//.phone
		}//phone-block

		.adres{ position: absolute; right: 1%; top: 110px; padding-top: 0px; width: 98%; outline: 0px solid;
			img{ float: left; width: 35px; height: 25px; display: none;}
			p{ margin: 5px  0; padding-left: 0; font-size: 14px; text-align: center; font-family: $gothic, sans-serif; color: #003663; 
				span{ font-size: 14px; color: #000; margin-right: 5px;}
			}
		}//.adres

		.language{ position: absolute; right: 1%; top: 0; outline: 0px solid;
			ul li{margin: 5px 0;
				a{ display: block; padding: 8px 6px; border-radius: 4px; text-decoration: none; text-align: center; font-size: 13px; font-family: $gothic, Arial,sans-serif; font-weight: bold; color:#fff; background: #DCDCDC;
					&:hover{ background: #0476C3; }	
				}
				a.active{ background: #0476C3; text-transform: uppercase; }
			}
		}//.language
	 }// Для телефонов с широким экраном


	// Для маленьких планшетов
	@include respond-to(small-tablets) { 
		.logo{ position: absolute; top: 11px; left: 0px; height: 125px; width: 250px;	
			img{ max-width: 100%; max-height: 100%; outline: 0px solid;}
		}//.logo

		.phone-block{ position: absolute; right: 10%; top: 10px; width: 37%; padding-top: 0;  outline: 0px solid;
			img{ float: left;  width: 35px; height: 25px; display: none;}
			.phone{ 
				height: 40px; width: auto; display: block;	
					p{ margin:5px 0; padding-left: 10px; font-size: 14px; text-align: center; font-family: $gothic, sans-serif; color: #003663;
						img{height:16px; width: 18px; margin-right: 5px; float: none; vertical-align: middle; display: inline-block;}
					}
			}//.phone
		}//phone-block

		.adres{ position: absolute; right: 1%; top: 110px; padding-top: 0px; width: 98%; outline: 0px solid;
			img{ float: left; width: 35px; height: 25px; display: none;}
			p{ margin: 5px  0; padding-left: 0; font-size: 14px; text-align: center; font-family: $gothic, sans-serif; color: #003663; 
				span{ font-size: 14px; color: #000; margin-right: 5px;}
			}
		}//.adres

		.language{ position: absolute; right: 1%; top: 0; outline: 0px solid;
			ul li{margin: 5px 0;
				a{ display: block; padding: 8px 6px; border-radius: 4px; text-decoration: none; text-align: center; font-size: 13px; font-family: $gothic, Arial,sans-serif; font-weight: bold; color:#fff; background: #DCDCDC;
					&:hover{ background: #0476C3; }	
				}
				a.active{ background: #0476C3; text-transform: uppercase; }
			}
		}//.language
	}// Для маленьких планшетов


	// Для планшетов
	@include respond-to(tablets) { 
		.logo{ position: absolute; top: 30px; left: 0px; height: 125px; width: 235px;	
			img{ max-width: 100%; max-height: 100%; outline: 0px solid;}
		}//.logo

		.phone-block{ position: absolute; right: 305px; top: 30px; padding-top: 0; outline: 0px solid;
			img{ float: left;  width: 35px; height: 25px;}
			.phone{ 
				height: 42px; width: 200px;	
					p{ margin:0; padding-left: 40px; font-size: 17px; font-family: Arial, sans-serif; color: #003663;
						img{height:16px; width: 18px; margin-right: 5px; float: none; vertical-align: middle;}
					}
			}//.phone
		}//phone-block

		.adres{ position: absolute; right: 50px; top: 12px; padding-top: 0px; width: 240px; outline: 0px solid;
			img{ float: left; width: 35px; height: 25px;}
			p{ margin: 6px 0;  font-size: 17px; font-family: $gothic, sans-serif; color: #003663; 
				span{ font-size: 14px; color: #000; margin-right: 5px;}
			}
		}//.adres

		.language{ position: absolute; right: 1%; top: 10px; outline: 0px solid;
			ul li{margin: 5px 0;
				a{ display: block; padding: 10px 4px; border-radius: 4px; text-decoration: none; text-align: center; font-size: 13px; font-family: $gothic, Arial,sans-serif; font-weight: bold; color:#fff; background: #DCDCDC;
					&:hover{ background: #0476C3; }	
				}
				a.active{ background: #0476C3; text-transform: uppercase; }
			}
		}//.language
	 }// Для планшетов


	// Для экранов
	@include respond-to(display) {

		.logo{ position: absolute; top: 20px; left: 0px; height: 125px; width: 344px;	
			img{ max-width: 100%; max-height: 100%; outline: 0px solid;}
		}//.logo

		.phone-block{ position: absolute; right: 350px; top: 20px; padding-top: 20px; outline: 0px solid; width:250px;
			img{ float: left; }
			.phone{ 
				/* height: 50px; */ width: 250px;	
					p{ margin:0; margin-bottom:6px; padding-left: 70px; font-size: 18px; font-family:  Arial, sans-serif; color: #003663;
						img{height:20px; width: 22px; margin-right: 10px; float: none; vertical-align: middle;}
					}
			}//.phone
		}//phone-block

		.adres{ position: absolute; right: 60px; top: 12px; padding-top: 20px; width: 270px; outline: 0px solid;
			img{ float: left; }
			p{ margin: 6px 0; font-size: 18px; font-family: $gothic, sans-serif; color: #003663; 
				span{ font-size: 14px; color: #000; margin-right: 5px;}
			}
		}//.adres

		.language{ position: absolute; right: 1%; top: 20px; outline: 0px solid;
			ul li{margin: 5px 0;
				a{ display: block; padding: 10px 4px; border-radius: 4px; text-decoration: none; text-align: center; font-size: 13px; font-family: $gothic, Arial,sans-serif; font-weight: bold; color:#fff; background: #DCDCDC;
					&:hover{ background: #0476C3; }	
				}
				a.active{ background: #0476C3; text-transform: uppercase; }
			}
		}//.language

	 }//// Для экранов


}

}



