.right-content{
	.padding{ padding-bottom: 1px;

		#luki-pod-pokrasku{ 

			.pod-pokrasku{ width: 100%; text-align: center;
				li{	
					margin: 1% 0.5% 2% 0.5%;
					width: 47%;
					@include pod-block-adaptive;

					.category{ margin: 0; display: block; text-align: center;
						img{ max-width: 100%; max-height: 260px; background-size: contain; -moz-box-shadow: 0px 0px 16px #4F6477; -webkit-box-shadow: 0px 0px 16px #4F6477; box-shadow: 0px 0px 16px #4F6477;}
					}
					a{ text-align: center; display: block; font-size: 18px; color: #F3240A; font-weight: 600; margin-top: 10px;
						&:hover{ color: #D52008;}
					}
				}
			}

		 }// luki-pod plitku


		 // Для телефонов
		 @include respond-to(handhelds) { 
		 	#luki-pod-pokrasku{
		 		.pod-pokrasku {
		 			li{
		 				a{ font-size: 13px;}
		 			}
		 		}
		 	}
		 }


		 // Для телефонов с широким экраном
		 @include respond-to(wide-handhelds) {
			#luki-pod-pokrasku{
				.pod-pokrasku {
					li{
						a{ font-size: 14px;}
					}
				}
			}
		 }


		 // Для маленьких планшетов
		 @include respond-to(small-tablets) { 
			#luki-pod-pokrasku{
				.pod-pokrasku {
					li{
						a{ font-size: 16px;}
					}
				}
			}
		 }


	} // .padding
} //.right-content




