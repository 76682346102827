//НАстройка бокового меню
#content{
	#global-left-content{

		// Для телефонов
		@include respond-to(handhelds) {  display: none;  }
		// Для телефонов с широким экраном
		@include respond-to(wide-handhelds) {  display: none;  }
		// Для маленьких планшетов
		@include respond-to(small-tablets) {  display: none;  }
		// Для планшетов
		@include respond-to(tablets) { display: none;  }
		// Для экранов
		@include respond-to(display) {   }


		.padding{ padding: 5% 1% 0% 3%; 

			// bokovoe menu
			#left-menu{ margin-bottom: 20px;
				background-color: #E3E3E3;

				li{
					
					
					a{
						display: block;
						padding: 14px 20px;
					    font-size: 16px;
					    line-height: 130%;
					    color: #003563;
					    text-align: center;
					    border-bottom: none;
					    font-family: $gothic, sans-serif;
					    text-decoration: none;
					}

					ul{
						li{ margin: 3px 0;

							a{ padding: 10px 10px 10px 30px; background: #fff; font-size: 15px; text-align: left;color: #048BD3;
								
								&:hover{ color: #fff; background: #F3240A;}
							}
						}
					}
				}
			}

			.news{
				margin-bottom: 20px;
				background: #E3E3E3;
				//-webkit-box-shadow: 0px 0px 11px 0px rgba(179,179,179,1);
			    //-moz-box-shadow: 0px 0px 11px 0px rgba(179,179,179,1);
			    //box-shadow: 0px 0px 11px 0px rgba(179,179,179,1);
			    border-top: 1px solid #ACACAC;
			    border-left: 2px solid #ACACAC;
			    border-right: 2px solid #ACACAC;
			    border-bottom: 4px solid #ACACAC;

// block s novostyami
		    div{
		    	margin-top: 10px;
		    	padding:10px;
		    	background: #fff;
		    	border-bottom: none;
		    	margin-bottom: 0;
		    	font-family: $calibri, sans-serif;

		    	a{	
		    		color: #F3240A;
					font-size: 16px;
					font-weight: bold;
					text-decoration: none;
					line-height: 130%;

					&:hover{ color: #DA220A;}
		    	}

		    	p{
		    		background: none;
		    		text-align:justify;
		    		font-size: 14px;
		    		margin: 10px 0;
		    		color: #000;
		    		text-transform: none ;
		    		line-height: normal;
		    		font-weight: 100;

		    		span{
		    			text-decoration: underline;
		    			font-size: 13px;
		    		}
		    	}

		    	button{
		    		@include btn;
		    		 width: 87px; height:27px; padding: 5px 10px;
		    	}

		    	@include clearfix;
		    }
		}


			h2{
				font-size: 16px;
			    color: #fff;
			    font-weight: bold;
			    line-height: 64px;
			    text-align: center;
			    text-transform: uppercase;
			    margin-left: -1px;
			    margin-right: -1px;
			    background-color: #032447;
			}

		}
	}

}