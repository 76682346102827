#content{  .padding{ padding-bottom: 1px ;}}
#contacty{ 

    h1.title{ padding: 10px 10px; margin: 10px 0;}

    .left-content{ width: 59%;  margin: 5px 0;
        
        // Для телефонов
        @include respond-to(handhelds) { width: 100%; float: none; }// Для телефонов
        // Для телефонов с широким экраном
        @include respond-to(wide-handhelds) { width: 100%; float: none; } // Для телефонов с широким экраном
        // Для маленьких планшетов
        @include respond-to(small-tablets) { width: 100%; float: none; }// Для маленьких планшетов

    }

    .right-content{ width: 39%;  margin: 5px 0;
        
        // Для телефонов
        @include respond-to(handhelds) { width: 100%; float: none; }// Для телефонов
        // Для телефонов с широким экраном
        @include respond-to(wide-handhelds) { width: 100%; float: none; } // Для телефонов с широким экраном
        // Для маленьких планшетов
        @include respond-to(small-tablets) { width: 100%; float: none; }// Для маленьких планшетов

    }
    .left-content .padding, .right-content .padding{ padding: 10px;}

    .shadow{ border-radius: 15px; padding: 10px;  -moz-box-shadow: 0px 1px 19px #a8a8a8;
-webkit-box-shadow: 0px 1px 19px #a8a8a8;
box-shadow: 0px 1px 19px #a8a8a8;}


    .markets{ width: 49%;
        @include pod-block-adaptive;

        .padding{ padding: 10px;

        // Для телефонов с широким экраном
        @include respond-to(wide-handhelds) { padding: 0; } // Для телефонов с широким экраном
            // Для маленьких планшетов
        @include respond-to(tablets) { padding: 0; }// Для маленьких планшетов
        }


        // Для телефонов
        @include respond-to(handhelds) { width: 100%;  }// Для телефонов
    }
    .second{
        p{ text-align: right;}
    }

    .markets {
        h2.blue-color{ font-size: 16px; padding: 5px 0; font-weight: bold; border-bottom: 1px solid red; margin: 10px 3px;}
        p{ line-height: 1.55;}
        p.work-table{ font-size: 13px; font-weight: bold; border-bottom: 1px solid #111; margin: 5px 5px 0 5px;}
        p.mail{
            a{ font-weight: bold; color: red;
                &:hover{ color: #BE0000;}
            }
        }
    }

    // form
    .form{
        form{ padding-right: 10px;}
        p{ line-height: 1.30; text-align: center; margin: 10px 0;}

        label{ font-size: 14px; margin-right: 20px;}
        input{ width: 92%; margin: 5px 0 10px 0; height: 25px; vertical-align: middle; background: none; border: 1px solid #111; border-radius: 4px; line-height: 25px; padding-left: 15px; font-size: 16px; padding-right: 10px;
            &:focus{ background: #fff; outline: none; border: 1px solid red; border-radius: 6px;}
        }
        textarea{ width: 92%; background: none; border: 1px solid #111; padding-left: 15px; padding-right: 10px; border-radius: 4px;
            &:focus{ background: #fff; outline: none; border: 1px solid red; border-radius: 6px;}
        }

        button.send{ width: 120px; margin: 15px 10px 0 0; font-size: 14px; text-align: center; padding: 6px 0; border-radius: 8px; border: 1px solid #ccc; float: right;
            &:active{ outline: none;}
         }
    }

    #map{ margin: 20px 0;}
}