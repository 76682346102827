#navigation{ padding: 0 0 5px 0;

/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#aebcbf+0,6e7774+36,0a0e0a+64,0a0809+100&0.7+0,0.54+100 */
background: -moz-linear-gradient(top, rgba(174,188,191,0.7) 0%, rgba(110,119,116,0.64) 36%, rgba(10,14,10,0.6) 64%, rgba(10,8,9,0.54) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(174,188,191,0.7) 0%,rgba(110,119,116,0.64) 36%,rgba(10,14,10,0.6) 64%,rgba(10,8,9,0.54) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(174,188,191,0.7) 0%,rgba(110,119,116,0.64) 36%,rgba(10,14,10,0.6) 64%,rgba(10,8,9,0.54) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3aebcbf', endColorstr='#8a0a0809',GradientType=0 ); /* IE6-9 */


    .menu-trigger{ cursor: pointer; font-size: 16px; padding: 10px 30px; font-weight: bold;  margin: 2px; color : #fff; border-radius: 8px; border: 0 solid #FF2A00; display: block; text-decoration: none; text-align: center; display:none}
   

    // Для телефонов
    @include respond-to(handhelds) { 
        .menu-trigger{display: block;}
        #main-menu{display: none;}
     }

    // Для телефонов с широким экраном
    @include respond-to(wide-handhelds) {
        .menu-trigger{display: block;}
        #main-menu{display: none;}
      }

    // Для маленьких планшетов
    @include respond-to(small-tablets) { 
        .menu-trigger{display: block;}
        #main-menu{display: none;}
     }// Для маленьких планшетов


    // Для планшетов
    @include respond-to(tablets) { 
        #menu{ outline: 0px solid red;
                
            .sm-clean{ border-radius: 1px !important;}
            .sm-clean a span.sub-arrow {
                top: 50%;
                margin-top: -2px;
                right: 0px;
            }
            #main-menu>li {width:12.5%; }
            #main-menu>li>a>span {top:45px; left:50%}
            #main-menu>li>a {color:#fff;}


            #main-menu { padding: 0; margin: 0 6px;
                li { 
                    .two-row{ padding: 11px;}
                    a{  font-family: $gothic, sans-serif;
                        padding: 20px 11.2px;
                        font-size: 16px;
                        display: block;

                        border-right: 1px solid #555555; text-align: center;

                        



                        &:hover{background: #FF2A00; color: #fff;}
                        &.active {background: #2e2e2e; color: #fff;}
                    }
                    ul{
                        li{
                            a{ padding: 10px 10px;  font-size: 15px; text-transform: lowercase;}
                        }
                    }
                }           
            } //#main-menu
        }//#menu
    }// Для планшетов


    // Для экранов
    @include respond-to(display) {
        #menu{ outline: 0px solid red;
                
            .sm-clean{ border-radius: 1px !important;}
            #main-menu>li {width:12.5%; border-right: 1px solid #555555; text-align: center;}

            #main-menu>li>a {color:#fff;}

            #main-menu { padding: 0; margin: 0 6px;
                li {  
                    .two-row{ padding: 11px;}
                    a{  font-family: $gothic, sans-serif;
                        padding: 20px 22px;
                        font-size: 18px;
                        display: block;



                        &:hover{background: #FF2A00; color: #fff;}
                        &.active {background: #2e2e2e; color: #fff;}
                    }
                    ul{
                        li{
                            a{ padding: 10px 10px; font-size: 15px; text-transform: lowercase;}
                        }
                    }
                }           
            } //#main-menu
        }//#menu
    }// Для экранов

}//#navigation

