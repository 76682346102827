
		#price-table{
			.padding{

				
				p{ text-align: center; font-size:14px; line-height:130%;}
				.model{text-align: center; margin: 15px 0 0 0; padding: 0; color: #0476C3;}
				.title-table{text-align: center; margin: 5px 0; padding:0; font-size: 20px; color: #0C4278; font-weight: bold;}
				

				// Для телефонов
				@include respond-to(handhelds) { 
					.table-img{ text-align: center;
						img{ max-width: 100%; max-height: 140px; background-size: cover; float: none; margin: 5px;}
					}
				}


				.table-img{ text-align: center;
					img{ max-width: 100%; max-height: 140px; background-size: cover; margin: 5px;}
				}

				table{ width: 99%; margin: 10px auto;  vertical-align: middle;  border-collapse: collapse;text-align: center;
					th{ color: #fff;  background: #0C4278; font-size: 14px; font-weight: 100; height: 45px; vertical-align: middle; border: 1px solid #000;
						@include respond-to(handhelds) { font-size: 12px; }
							@include respond-to(wide-handhelds) { font-size: 13px; }

						td{ padding: 5px 0;}
					}

					tr:nth-child(odd) {background-color: #ccc;}
					tr:nth-child(even) {background-color:#fff;}

					tr{ border: 1px solid #000;
						&:hover{ background: #FF2200; color: #fff;
							a{ color: #fff;}
						}
						td{ vertical-align: middle; height: 30px; margin: 0; padding: 0;  font-size: 14px; border-right: 1px solid #111;
							a{ color: #FF2200;}

							// Для телефонов
							@include respond-to(handhelds) { font-size: 12px; }
							@include respond-to(wide-handhelds) { font-size: 13px; }
						}
					}
				} // table

				

				.left-content {width: 49%; float: left;
				.padding { padding: 1% 0; }
				
					// Для телефонов до 480
					@include respond-to(handhelds) { display: inline-block; float: none; width: 100%; };
					// Для телефонов с широким экраном до 767
					@include respond-to(wide-handhelds) { display: inline-block; float: none; width: 100%; };
					// Для маленьких планшетов
					@include respond-to(small-tablets) { display: inline-block; };
					// Для планшетов до 979
					@include respond-to(tablets) { display: inline-block; };
					// Для экранов от 980
					@include respond-to(display) {  };

				}//.left-content

				.right-content {width: 49%; float: right;
				.padding { padding: 1% 0;}
						  	
					// Для телефонов до 480
					@include respond-to(handhelds) { float: none; width: 100%; };
					// Для телефонов с широким экраном до 767
					@include respond-to(wide-handhelds) { float: none; width: 100%; };

				}//.right-content
				


				} 
			} //#price-table

