#video-galery{ 

	.video{ width: 100%; text-align: center;
		li{	
			margin: 1% 0.5% 1% 0.5%;
			width: 48%;
			@include pod-block-adaptive;

			// Для телефонов
			@include respond-to(handhelds) { width: 100%; }
			// Для телефонов с широким экраном
			@include respond-to(wide-handhelds) { width: 100%;  }
			// Для планшетов до 979
			@include respond-to(small-tablets) { width: 100%;  };
		}
	}

 }// video-galery


