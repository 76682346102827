#photo-galery{ 

	.photo{ width: 100%; text-align: center;
		li{	
			margin: 1% 0.5% 1% 0.5%;
			width: 23.40%;
			@include pod-block-adaptive;

			.category{ margin: 0; display: block; text-align: center;
				img{ border: 2px solid #ccc; max-width: 100%; max-height: 260px; background-size: contain; -moz-box-shadow: 0px 0px 16px #4F6477; -webkit-box-shadow: 0px 0px 16px #4F6477; box-shadow: 0px 0px 16px #4F6477;}

			}

			// Для телефонов
			@include respond-to(handhelds) { width: 43%; }
			// Для телефонов с широким экраном
			@include respond-to(wide-handhelds) { width: 43%;  }
		}
	}

 }// photo-galery


