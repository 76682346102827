#content{
	.right-content{



		
// Для телефонов
		@include respond-to(handhelds) { width: 100%;  .padding { padding: 2% 1% 0% 1%;}}
		// Для телефонов с широким экраном
		@include respond-to(wide-handhelds) { width: 100%;  .padding { padding: 2% 1% 0% 1%;}}
		// Для маленьких планшетов
		@include respond-to(small-tablets) { width: 100%;  .padding { padding: 2% 1% 0% 1%;}}
		// Для планшетов
		@include respond-to(tablets) { width: 100%; 
			.padding { padding: 2% 1% 0% 1%;}
		 }
		// Для экранов
		@include respond-to(display) {  }




// форма поиска
.top-search{
	input{ width: 87%; float: left; line-height: 40px; border-radius: 6px; margin: 0 2% 15px 0; outline: none; border: 1px solid #DCDCDC; background: #fff; padding-left: 5%; font-size: 14px; 

		// Для телефонов
		@include respond-to(handhelds) { width: 73%; margin-left: 2%; margin-right: 5%; padding-left: 2%; }
		// Для телефонов с широким экраном
		@include respond-to(wide-handhelds) { width: 82%; }
		// Для маленьких планшетов
		@include respond-to(small-tablets) { width: 82%;  }

	}
		input::-moz-input-placeholder     {color: #000;} 
		input::-webkit-input-placeholder  {color: #000;} 
		input::-ms-input-placeholder {color: #000;}  

		button.search{width:5%; float: left; height: 40px; background: transparent url(../img/button_search.png) no-repeat; background-size: contain; border: none;
			
			// Для телефонов
			@include respond-to(handhelds) { width: 15%;  }
			// Для телефонов с широким экраном
			@include respond-to(wide-handhelds) { width: 10%;  }
			// Для маленьких планшетов
			@include respond-to(small-tablets) { width: 10%;  }


			&:hover{ background: url(../img/button_search_hover.png) no-repeat;  background-size: contain }
			&:active, &:focus{ border: none; outline: none; }

		}


			@include clearfix;
}


	.history{ background: #032447; 
		.padding{ padding: 10px 20px; 

			span {font-size: 15px; border-right: none; color: #7D7D7D;

a{ font-size: 13px; color: #fff; padding: 4px 5px;
						&:hover{ color: red;}

			}
}



			ul{
				li:last-child {  
					a{ font-size: 15px; border-right: none; color: #7D7D7D;
						&:hover{ color: #7D7D7D}
					} 
				}
				li{ margin: 0; display: inline-block;  
					a{ font-size: 13px; color: #fff; text-align: center; display: block; padding: 4px 5px;
						&:hover{ color: red;}
					}
				}
				
			}
		}
	 }
	
	
	
	.bg-grey{ background: #E3E3E3;
		.padding{ padding: 3%;
			p{ font-size: 16px; font-family: $gothic, sans-serif; font-weight: 500; line-height: 20px;}

			ul{ margin: 10px 0;

				@include counter(li);

				li{margin: 4px 0; font-family:$gothic,sans-serif; font-size:16px; font-weight:500;}

				li:before{
					margin-right: 10px;
					background: #F20A0A;
					font-size: 2px;
					padding: 1px 2px;
					border-radius: 50%;
					display: inline-block;
					vertical-align: middle;
				}
			}

			button{
				@include btn;
				margin: 20px 0 10px 0;
				width: 103px;
				height: 31px;
			}
			 @include clearfix;
		}
	}

	//настройка второго блока с текстом
	.second-block{
		.padding{padding: 2% 3%; font-family:$gothic,sans-serif;
			.orange{text-align: center; color: #F26C4F; font-weight: bold; font-size: 18px;}
			

			h1 {font-size:17px; line-height: 130%; margin:20px 0px 10px 0px; }
			h2 {font-size:15px; line-height: 130%; margin:10px 0px 5px 0px; }

			p{ line-height: 130%; font-size: 13px;
				span{ font-size: 13px; color: #F26C4F; font-weight: bold;}
			}
			ul{ margin: 10px 0;

				@include counter(li);

				li{margin: 4px 0;}

				li:before{
					margin-right: 10px;
					background: #F26C4F;
					color: #F26C4F;
					font-size: 2px;
					padding: 4px 4px;
					border-radius: 20%;
					display: inline-block;
					vertical-align: middle;
				}
			}
		}
	}
	}
}






