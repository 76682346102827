#about-company{
	.padding{

		
		.svidoctvo{ float: left; margin: 0 10px 10px 0;
			a{
				img{ max-height: 300px; max-width: 100%;}
			}
		}



		p{ font-size:14px; line-height: 1.55; text-indent: 20px; margin: 10px 0; text-align: justify;}

		#tips{ font-size: 14px; }

		
		// Для телефонов
		@include respond-to(handhelds) { 
			.svidoctvo{ float: none; margin: 0 10px 10px 0; text-align: center;
				a{
					img{ max-height: 300px; max-width: 100%;}
				}
			}

			p{ font-size:14px; font-family: $calibri; line-height: 1.55; text-indent: 20px; margin: 10px 0; text-align: left;}
		  }// Для телефонов
		
		// Для телефонов
		@include respond-to(handhelds) { 

			ul.doc{
				li{
					@include pod-block-adaptive;
					width: 47.1%;
				 	min-height: 200px;
					margin: 2% 1%;

					a{
						img{max-width: 100%; max-height: 360px; margin: 0 auto; background-size: contain;}
					}
				}
			}/* // ul.doc */
		  }// Для телефонов


		// Для телефонов с широким экраном
		@include respond-to(wide-handhelds) { 

			ul.doc{
				li{
					@include pod-block-adaptive;
					width: 30.60%;
				 	min-height: 200px;
					margin: 2% 1%;

					a{
						img{max-width: 100%; max-height: 360px; margin: 0 auto; background-size: contain;}
					}
				}
			}/* // ul.doc */
		 }// Для телефонов с широким экраном


		// Для маленьких планшетов
		@include respond-to(small-tablets) { 

			ul.doc{
				li{
					@include pod-block-adaptive;
					width: 30.80%;
				 	min-height: 200px;
					margin: 2% 1%;

					a{
						img{max-width: 100%; max-height: 360px; margin: 0 auto; background-size: contain;}
					}
				}
			}/* // ul.doc */
		 }


		// Для планшетов
		@include respond-to(tablets) {
			ul.doc{
				li{
					@include pod-block-adaptive;
					width: 30.86%;
				 	min-height: 200px;
					margin: 2% 1%;

					a{
						img{max-width: 100%; max-height: 360px; margin: 0 auto; background-size: contain;}
					}
				}
			}/* // ul.doc */

		 }


		// Для экранов
		@include respond-to(display) {

		ul.doc{
			li{
				@include pod-block-adaptive;
				width: 30.86%;
			 	min-height: 200px;
				margin: 2% 1%;

				a{
					img{max-width: 100%; max-height: 360px; margin: 0 auto; background-size: contain;}
				}
			}
		}/* // ul.doc */
		}// Для экранов

		
	}
}	

.download{ line-height: 30px; color: #FF2A00; display: block;
	img{ margin-right: 20px; max-height: 70px; max-width: 100%; vertical-align: middle;}

}
// Для телефонов
@include respond-to(handhelds) { 
	.download{ line-height: 20px; color: #FF2A00; display: block; font-size: 12px; margin-bottom: 5px;
		img{ margin-right: 0; max-height: 30px; max-width: 100%; vertical-align: middle;}
	}
 }// Для телефонов

// Для телефонов с широким экраном
@include respond-to(wide-handhelds) {
	.download{ line-height: 40px; color: #FF2A00; display: block; font-size: 13px;
		img{ margin-right: 5px; max-height: 40px; max-width: 100%; vertical-align: middle;}
	}
}// Для телефонов с широким экраном

// Для маленьких планшетов
@include respond-to(small-tablets) {
	.download{ line-height: 40px; color: #FF2A00; display: block; font-size: 14px;
		img{ margin-right: 20px; max-height: 40px; max-width: 100%; vertical-align: middle;}
	}
 }// Для маленьких планшетов

// Для планшетов
@include respond-to(tablets) {
		.download{ line-height: 50px; color: #FF2A00; display: block; font-size: 14px;
	img{ margin-right: 20px; max-height: 50px; max-width: 100%; vertical-align: middle;}
	}
}// Для планшетов