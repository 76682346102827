.right-content{
	.padding{ padding-bottom: 1px;
		#model{

			h2.title{ padding-bottom: 0;}

			.left-content-inner { width: 48%; float:left;
				.padding{ padding: 0 1%;}
				// Для телефонов до 480
				@include respond-to(handhelds) { display: block; float: none; width: 100%; };// Для телефонов до 480
				// Для телефонов с широким экраном до 767
				@include respond-to(wide-handhelds) { display: block; float: none; width: 100%; };// Для телефонов с широким экраном до 767
			 }
			.right-content-inner { width: 48%; float:right;

                // Для телефонов до 480
            @include respond-to(handhelds) { float: none; width: 100%; };
            // Для телефонов с широким экраном до 767
            @include respond-to(wide-handhelds) { float: none; width: 100%; };

				.padding{ padding: 0 1%;
				// Для телефонов до 480
				@include respond-to(handhelds) { padding: 3% 0 3% 3%; };// Для телефонов до 480
				// Для телефонов с широким экраном до 767
				@include respond-to(wide-handhelds) { padding: 3% 0 3% 5%; };// Для телефонов с широким экраном до 767

				

				// Для телефонов до 480
				@include respond-to(handhelds) { display: block; float: none; width: 100%; };// Для телефонов до 480
				// Для телефонов с широким экраном до 767
				@include respond-to(wide-handhelds) { display: block; float: none; width: 100%; };// Для телефонов с широким экраном до 767
			
            ul{ list-style-type : square; line-height: 1.55; margin: 5px 0 15px 5px; 
            h2.title {} }

            p {font-size: 14px; line-height: 130%; margin-bottom:5px;}

            p.price{ width: 170px; text-align: center; line-height: 1.55; padding: 5px 0px; background: #ccc; display: inline-block; margin: 15px 10px 15px 0;
            span{ font-size: 16px; font-weight: 600; color: #032447; margin: 0 5px;}

            // Для телефонов до 480
                @include respond-to(handhelds) { display: block; width: 70%; margin: 10px auto; };// Для телефонов до 480
                }

        a.zakazat{ width: 170px; text-align: center; display: inline-block; font-size: 17px; padding: 7px 0px; font-weight: bold; color: #fff; background: #032447; border: none; margin: 15px 0 15px 0;
            &:hover{ background: #FF2A00;}

            // Для телефонов до 480
                @include respond-to(handhelds) { display: block; width: 70%; margin: 10px auto; };// Для телефонов до 480
        }



        } //.padding



            } //.right-content


			
			// Для телефонов с широким экраном до 767
			@include respond-to(wide-handhelds) { display: block; float: none; width: 100%; };// Для телефонов с широким экраном до 767
			// Для маленьких планшетов
			@include respond-to(small-tablets) { display: block; };// Для маленьких планшетов

		}// #model	


		


        // настройка акордиона
        .responsive-tabs{ margin-top: 10px; margin-bottom:10px;
           
            
         
        }

        .resp-tab-content {

                            @include respond-to(handhelds) { padding:5px; }
                            @include respond-to(wide-handhelds) { padding:10px; }

        }

		.resp-tabs-container { 
        
    

			p{ font-size:14px; line-height: 1.3; text-align: left;

                @include respond-to(handhelds) { font-size: 12px; }
                            @include respond-to(wide-handhelds) { font-size: 13px; }

            }
            ul {margin: 5px 0px 10px 20px;}

            ul li {text-align: left; font-size:14px; line-height: 1.3; list-style-type: circle;

                            @include respond-to(handhelds) { font-size: 12px; }
                            @include respond-to(wide-handhelds) { font-size: 13px; }
            }    

			.shema{ font-weight: bold; margin : 10px 0; text-align: center;}

			.rest-tabs-img{ text-align: center; padding: 5px; 
				img{ max-height: 100%; max-width: 100%; background-size: contain;}
			}
		}

        .opis-tab {text-align:center; p{text-align:left; margin-bottom:5px;} img {max-width:100%; margin:5px;} }


			
	} // .padding
} //.right-content



.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    cursor: default;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    z-index: 1;

    -webkit-transition: opacity .5s;
    -moz-transition: opacity .5s;
    -ms-transition: opacity .5s;
    -o-transition: opacity .5s;
    transition: opacity .5s;
}
.overlay:target {
    visibility: visible;
}
.popup {
	width: 400px;
	min-height: 250px;
    display: inline-block;
    position: fixed;
    visibility: hidden;
    z-index: 10;
    left: 50%;
    background: #fff;

    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px; 

    -moz-box-shadow: -2px 3px 18px #000000;
    -webkit-box-shadow: -2px 3px 18px #000000;
    box-shadow: -2px 3px 18px #000000;


    // Для телефонов
    @include respond-to(handhelds) { width: 95%;  }
    // Для телефонов с широким экраном
    @include respond-to(wide-handhelds) { width: 90%;  }
    // Для маленьких планшетов
    @include respond-to(small-tablets) { width: 80%;  }
    // Для планшетов
    @include respond-to(tablets) { width: 70%;  }

    .padding{ padding: 10px;}

    h1.title{ margin: 0 0 10px 0; padding: 5px 15px;}
    
    p {margin-bottom:10px;}

    label{ font-size: 15px; margin-right: 20px; display: block; color: #111;}
    input, textarea{width: 90%; margin: 2px 0 5px 0; height: 24px; line-height: 24px; vertical-align: middle; font-size: 14px; border: 1px solid #111; background: none; padding: 0 10px 0 15px; border-radius: 6px;
		&:focus{ background: #fff; border: 1px solid red; border-radius: 10px; outline: none;}
    }
    textarea#zapros{width: 90% !important; }

    button.send{ width: 100px; padding: 6px 0; text-align:center; font-size: 16px; background: red; color: #fff; margin: 5px 0 0 0px; border-radius: 8px; border: none;
		&:hover{ border-radius: 10px; }
    }
}


.overlay:target+.popup {
    top: 50%;
    opacity: 1;
    visibility: visible;

    // Для телефонов
    @include respond-to(handhelds) { top: 35%;  }
    // Для телефонов с широким экраном
    @include respond-to(wide-handhelds) { top: 33%;  }
    // Для маленьких планшетов
    @include respond-to(small-tablets) { top: 30%;  }
    // Для планшетов
    @include respond-to(tablets) {  top: 35%;  }
}

.close:before {color: #fff; content: "X"; font-size: 24px;}
.close {
    background-color: rgba(4, 106, 249, 0.87);
    height: 35px;
    line-height: 33px;
    position: absolute;
    right: 0;
    text-align: center;
    text-decoration: none;
    top: 0px;
    width: 50px;
    border-radius: 0px 10px 0 10px;

    -webkit-border-radius: 0px 10px 0 10px;
    -moz-border-radius: 0px 10px 0 10px;
    -ms-border-radius: 0px 10px 0 10px;
    -o-border-radius:0px 10px 0 10px;

    &:hover {background-color: red;}
}
    
  
    


