
#content{
	.right-content{
		.padding{ padding-bottom: 1px;}
		#uslugi{
			.block-uslug{ margin-bottom: 10px;

			h2{ font-size: 16px; padding: 0 10px; margin: 15px 0 5px 0; text-align: left;}

			img{ float: left; margin : 5px 10px; max-width: 100%; max-height: 160px; background-size: contain;}

			p{ text-align: justify;}

			// Для телефонов
			@include respond-to(handhelds) { 

				img{ float: none;}
			  }// Для телефонов

			@include clearfix;

			} //.block-uslug
		} // .text
	} //.right-content
} //#content


