//Карусель
#owl-demo{
	margin-bottom: 10px;

	.item{

		img{ display: block;  width: 100%; height: auto;}
	}
}

.owl-theme .owl-controls .owl-buttons div {
  padding: 7px 14px;
}
 
.owl-theme .owl-buttons i{
  margin-top: 2px;
}
 
//To move navigation buttons outside use these settings:
 
.owl-theme .owl-controls .owl-buttons div {
  position: absolute;
}
 
.owl-theme .owl-controls .owl-buttons .owl-prev{
  left: 40px;
  top: 43%; 
}
 
.owl-theme .owl-controls .owl-buttons .owl-next{
  right: 40px;
  top: 43%;
}

.owl-theme .owl-controls .owl-buttons div {
    font-size: 30px;
    font-weight: bold;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background: #000000;
    filter: Alpha(Opacity=60);
    opacity: 0.6;
}
