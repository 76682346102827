@charset "UTF-8";
@font-face {
  font-family: "Calibri";
  src: url("../fonts/Calibri.otf");
  src: url("../fonts/Calibri.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* @font-face {
    font-family: "franklin-gothic-medium";
    src: url("../fonts/franklin-gothic-medium.otf");
    src: url("../fonts/franklin-gothic-medium.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
} */
/* $calibri : 'Calibri'; */
/* $gothic : 'franklin-gothic-medium'; */
body {
  font-family: Arial, sans-serif; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none; }

#header {
  min-height: 170px; }
  @media only screen and (max-width: 479px) {
    #header {
      height: 210px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #header {
      height: 175px; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #header {
      height: 170px; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #header {
      height: 140px; } }
  #header .pod-block {
    background: #ffffff; }
    @media only screen and (max-width: 479px) {
      #header .pod-block .logo {
        position: absolute;
        top: 8px;
        left: 0px;
        height: 125px;
        width: 210px; }
        #header .pod-block .logo img {
          max-width: 100%;
          max-height: 100%;
          outline: 0px solid; }
      #header .pod-block .phone-block {
        position: absolute;
        right: 1%;
        top: 96px;
        width: 98%;
        padding-top: 0;
        outline: 0px solid; }
        #header .pod-block .phone-block img {
          float: left;
          width: 35px;
          height: 25px;
          display: none; }
        #header .pod-block .phone-block .phone {
          height: 40px;
          width: 49%;
          display: inline-block; }
          #header .pod-block .phone-block .phone p {
            margin: 5px 0;
            padding-left: 0;
            font-size: 14px;
            text-align: center;
            font-family: Arial, sans-serif;
            color: #003663; }
            #header .pod-block .phone-block .phone p img {
              height: 16px;
              width: 18px;
              margin-right: 5px;
              float: none;
              vertical-align: middle;
              display: inline-block; }
      #header .pod-block .adres {
        position: absolute;
        right: 1%;
        top: 145px;
        padding-top: 0px;
        width: 98%;
        outline: 0px solid; }
        #header .pod-block .adres img {
          float: left;
          width: 35px;
          height: 25px;
          display: none; }
        #header .pod-block .adres p {
          margin: 4px  0;
          padding-left: 0;
          font-size: 14px;
          text-align: center;
          font-family: Arial, sans-serif;
          color: #003663; }
          #header .pod-block .adres p span {
            font-size: 14px;
            color: #000;
            margin-right: 5px; }
      #header .pod-block .language {
        position: absolute;
        right: 1%;
        top: 0;
        outline: 0px solid; }
        #header .pod-block .language ul li {
          margin: 5px 0; }
          #header .pod-block .language ul li a {
            display: block;
            padding: 6px 15px;
            border-radius: 4px;
            text-decoration: none;
            text-align: center;
            font-size: 13px;
            font-family: Arial, Arial, sans-serif;
            font-weight: bold;
            color: #fff;
            background: #DCDCDC; }
            #header .pod-block .language ul li a:hover {
              background: #0476C3; }
          #header .pod-block .language ul li a.active {
            background: #0476C3;
            text-transform: uppercase; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #header .pod-block .logo {
        position: absolute;
        top: 11px;
        left: 0px;
        height: 125px;
        width: 250px; }
        #header .pod-block .logo img {
          max-width: 100%;
          max-height: 100%;
          outline: 0px solid; }
      #header .pod-block .phone-block {
        position: absolute;
        right: 10%;
        top: 10px;
        width: 37%;
        padding-top: 0;
        outline: 0px solid; }
        #header .pod-block .phone-block img {
          float: left;
          width: 35px;
          height: 25px;
          display: none; }
        #header .pod-block .phone-block .phone {
          height: 40px;
          width: auto;
          display: block; }
          #header .pod-block .phone-block .phone p {
            margin: 5px 0;
            padding-left: 10px;
            font-size: 14px;
            text-align: center;
            font-family: Arial, sans-serif;
            color: #003663; }
            #header .pod-block .phone-block .phone p img {
              height: 16px;
              width: 18px;
              margin-right: 5px;
              float: none;
              vertical-align: middle;
              display: inline-block; }
      #header .pod-block .adres {
        position: absolute;
        right: 1%;
        top: 110px;
        padding-top: 0px;
        width: 98%;
        outline: 0px solid; }
        #header .pod-block .adres img {
          float: left;
          width: 35px;
          height: 25px;
          display: none; }
        #header .pod-block .adres p {
          margin: 5px  0;
          padding-left: 0;
          font-size: 14px;
          text-align: center;
          font-family: Arial, sans-serif;
          color: #003663; }
          #header .pod-block .adres p span {
            font-size: 14px;
            color: #000;
            margin-right: 5px; }
      #header .pod-block .language {
        position: absolute;
        right: 1%;
        top: 0;
        outline: 0px solid; }
        #header .pod-block .language ul li {
          margin: 5px 0; }
          #header .pod-block .language ul li a {
            display: block;
            padding: 8px 6px;
            border-radius: 4px;
            text-decoration: none;
            text-align: center;
            font-size: 13px;
            font-family: Arial, Arial, sans-serif;
            font-weight: bold;
            color: #fff;
            background: #DCDCDC; }
            #header .pod-block .language ul li a:hover {
              background: #0476C3; }
          #header .pod-block .language ul li a.active {
            background: #0476C3;
            text-transform: uppercase; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #header .pod-block .logo {
        position: absolute;
        top: 11px;
        left: 0px;
        height: 125px;
        width: 250px; }
        #header .pod-block .logo img {
          max-width: 100%;
          max-height: 100%;
          outline: 0px solid; }
      #header .pod-block .phone-block {
        position: absolute;
        right: 10%;
        top: 10px;
        width: 37%;
        padding-top: 0;
        outline: 0px solid; }
        #header .pod-block .phone-block img {
          float: left;
          width: 35px;
          height: 25px;
          display: none; }
        #header .pod-block .phone-block .phone {
          height: 40px;
          width: auto;
          display: block; }
          #header .pod-block .phone-block .phone p {
            margin: 5px 0;
            padding-left: 10px;
            font-size: 14px;
            text-align: center;
            font-family: Arial, sans-serif;
            color: #003663; }
            #header .pod-block .phone-block .phone p img {
              height: 16px;
              width: 18px;
              margin-right: 5px;
              float: none;
              vertical-align: middle;
              display: inline-block; }
      #header .pod-block .adres {
        position: absolute;
        right: 1%;
        top: 110px;
        padding-top: 0px;
        width: 98%;
        outline: 0px solid; }
        #header .pod-block .adres img {
          float: left;
          width: 35px;
          height: 25px;
          display: none; }
        #header .pod-block .adres p {
          margin: 5px  0;
          padding-left: 0;
          font-size: 14px;
          text-align: center;
          font-family: Arial, sans-serif;
          color: #003663; }
          #header .pod-block .adres p span {
            font-size: 14px;
            color: #000;
            margin-right: 5px; }
      #header .pod-block .language {
        position: absolute;
        right: 1%;
        top: 0;
        outline: 0px solid; }
        #header .pod-block .language ul li {
          margin: 5px 0; }
          #header .pod-block .language ul li a {
            display: block;
            padding: 8px 6px;
            border-radius: 4px;
            text-decoration: none;
            text-align: center;
            font-size: 13px;
            font-family: Arial, Arial, sans-serif;
            font-weight: bold;
            color: #fff;
            background: #DCDCDC; }
            #header .pod-block .language ul li a:hover {
              background: #0476C3; }
          #header .pod-block .language ul li a.active {
            background: #0476C3;
            text-transform: uppercase; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #header .pod-block .logo {
        position: absolute;
        top: 30px;
        left: 0px;
        height: 125px;
        width: 235px; }
        #header .pod-block .logo img {
          max-width: 100%;
          max-height: 100%;
          outline: 0px solid; }
      #header .pod-block .phone-block {
        position: absolute;
        right: 305px;
        top: 30px;
        padding-top: 0;
        outline: 0px solid; }
        #header .pod-block .phone-block img {
          float: left;
          width: 35px;
          height: 25px; }
        #header .pod-block .phone-block .phone {
          height: 42px;
          width: 200px; }
          #header .pod-block .phone-block .phone p {
            margin: 0;
            padding-left: 40px;
            font-size: 17px;
            font-family: Arial, sans-serif;
            color: #003663; }
            #header .pod-block .phone-block .phone p img {
              height: 16px;
              width: 18px;
              margin-right: 5px;
              float: none;
              vertical-align: middle; }
      #header .pod-block .adres {
        position: absolute;
        right: 50px;
        top: 12px;
        padding-top: 0px;
        width: 240px;
        outline: 0px solid; }
        #header .pod-block .adres img {
          float: left;
          width: 35px;
          height: 25px; }
        #header .pod-block .adres p {
          margin: 6px 0;
          font-size: 17px;
          font-family: Arial, sans-serif;
          color: #003663; }
          #header .pod-block .adres p span {
            font-size: 14px;
            color: #000;
            margin-right: 5px; }
      #header .pod-block .language {
        position: absolute;
        right: 1%;
        top: 10px;
        outline: 0px solid; }
        #header .pod-block .language ul li {
          margin: 5px 0; }
          #header .pod-block .language ul li a {
            display: block;
            padding: 10px 4px;
            border-radius: 4px;
            text-decoration: none;
            text-align: center;
            font-size: 13px;
            font-family: Arial, Arial, sans-serif;
            font-weight: bold;
            color: #fff;
            background: #DCDCDC; }
            #header .pod-block .language ul li a:hover {
              background: #0476C3; }
          #header .pod-block .language ul li a.active {
            background: #0476C3;
            text-transform: uppercase; } }
    @media only screen and (min-width: 980px) {
      #header .pod-block .logo {
        position: absolute;
        top: 20px;
        left: 0px;
        height: 125px;
        width: 344px; }
        #header .pod-block .logo img {
          max-width: 100%;
          max-height: 100%;
          outline: 0px solid; }
      #header .pod-block .phone-block {
        position: absolute;
        right: 350px;
        top: 20px;
        padding-top: 20px;
        outline: 0px solid;
        width: 250px; }
        #header .pod-block .phone-block img {
          float: left; }
        #header .pod-block .phone-block .phone {
          /* height: 50px; */
          width: 250px; }
          #header .pod-block .phone-block .phone p {
            margin: 0;
            margin-bottom: 6px;
            padding-left: 70px;
            font-size: 18px;
            font-family: Arial, sans-serif;
            color: #003663; }
            #header .pod-block .phone-block .phone p img {
              height: 20px;
              width: 22px;
              margin-right: 10px;
              float: none;
              vertical-align: middle; }
      #header .pod-block .adres {
        position: absolute;
        right: 60px;
        top: 12px;
        padding-top: 20px;
        width: 270px;
        outline: 0px solid; }
        #header .pod-block .adres img {
          float: left; }
        #header .pod-block .adres p {
          margin: 6px 0;
          font-size: 18px;
          font-family: Arial, sans-serif;
          color: #003663; }
          #header .pod-block .adres p span {
            font-size: 14px;
            color: #000;
            margin-right: 5px; }
      #header .pod-block .language {
        position: absolute;
        right: 1%;
        top: 20px;
        outline: 0px solid; }
        #header .pod-block .language ul li {
          margin: 5px 0; }
          #header .pod-block .language ul li a {
            display: block;
            padding: 10px 4px;
            border-radius: 4px;
            text-decoration: none;
            text-align: center;
            font-size: 13px;
            font-family: Arial, Arial, sans-serif;
            font-weight: bold;
            color: #fff;
            background: #DCDCDC; }
            #header .pod-block .language ul li a:hover {
              background: #0476C3; }
          #header .pod-block .language ul li a.active {
            background: #0476C3;
            text-transform: uppercase; } }

#navigation {
  padding: 0 0 5px 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#aebcbf+0,6e7774+36,0a0e0a+64,0a0809+100&0.7+0,0.54+100 */
  background: -moz-linear-gradient(top, rgba(174, 188, 191, 0.7) 0%, rgba(110, 119, 116, 0.64) 36%, rgba(10, 14, 10, 0.6) 64%, rgba(10, 8, 9, 0.54) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(174, 188, 191, 0.7) 0%, rgba(110, 119, 116, 0.64) 36%, rgba(10, 14, 10, 0.6) 64%, rgba(10, 8, 9, 0.54) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(174, 188, 191, 0.7) 0%, rgba(110, 119, 116, 0.64) 36%, rgba(10, 14, 10, 0.6) 64%, rgba(10, 8, 9, 0.54) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3aebcbf', endColorstr='#8a0a0809',GradientType=0 );
  /* IE6-9 */ }
  #navigation .menu-trigger {
    cursor: pointer;
    font-size: 16px;
    padding: 10px 30px;
    font-weight: bold;
    margin: 2px;
    color: #fff;
    border-radius: 8px;
    border: 0 solid #FF2A00;
    display: block;
    text-decoration: none;
    text-align: center;
    display: none; }
  @media only screen and (max-width: 479px) {
    #navigation .menu-trigger {
      display: block; }
    #navigation #main-menu {
      display: none; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #navigation .menu-trigger {
      display: block; }
    #navigation #main-menu {
      display: none; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #navigation .menu-trigger {
      display: block; }
    #navigation #main-menu {
      display: none; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #navigation #menu {
      outline: 0px solid red; }
      #navigation #menu .sm-clean {
        border-radius: 1px !important; }
      #navigation #menu .sm-clean a span.sub-arrow {
        top: 50%;
        margin-top: -2px;
        right: 0px; }
      #navigation #menu #main-menu > li {
        width: 12.5%; }
      #navigation #menu #main-menu > li > a > span {
        top: 45px;
        left: 50%; }
      #navigation #menu #main-menu > li > a {
        color: #fff; }
      #navigation #menu #main-menu {
        padding: 0;
        margin: 0 6px; }
        #navigation #menu #main-menu li .two-row {
          padding: 11px; }
        #navigation #menu #main-menu li a {
          font-family: Arial, sans-serif;
          padding: 20px 11.2px;
          font-size: 16px;
          display: block;
          border-right: 1px solid #555555;
          text-align: center; }
          #navigation #menu #main-menu li a:hover {
            background: #FF2A00;
            color: #fff; }
          #navigation #menu #main-menu li a.active {
            background: #2e2e2e;
            color: #fff; }
        #navigation #menu #main-menu li ul li a {
          padding: 10px 10px;
          font-size: 15px;
          text-transform: lowercase; } }
  @media only screen and (min-width: 980px) {
    #navigation #menu {
      outline: 0px solid red; }
      #navigation #menu .sm-clean {
        border-radius: 1px !important; }
      #navigation #menu #main-menu > li {
        width: 12.5%;
        border-right: 1px solid #555555;
        text-align: center; }
      #navigation #menu #main-menu > li > a {
        color: #fff; }
      #navigation #menu #main-menu {
        padding: 0;
        margin: 0 6px; }
        #navigation #menu #main-menu li .two-row {
          padding: 11px; }
        #navigation #menu #main-menu li a {
          font-family: Arial, sans-serif;
          padding: 20px 22px;
          font-size: 18px;
          display: block; }
          #navigation #menu #main-menu li a:hover {
            background: #FF2A00;
            color: #fff; }
          #navigation #menu #main-menu li a.active {
            background: #2e2e2e;
            color: #fff; }
        #navigation #menu #main-menu li ul li a {
          padding: 10px 10px;
          font-size: 15px;
          text-transform: lowercase; } }

@media only screen and (max-width: 479px) {
  #content #global-left-content {
    display: none; } }

@media only screen and (min-width: 480px) and (max-width: 599px) {
  #content #global-left-content {
    display: none; } }

@media only screen and (min-width: 600px) and (max-width: 767px) {
  #content #global-left-content {
    display: none; } }

@media only screen and (min-width: 768px) and (max-width: 979px) {
  #content #global-left-content {
    display: none; } }

#content #global-left-content .padding {
  padding: 5% 1% 0% 3%; }
  #content #global-left-content .padding #left-menu {
    margin-bottom: 20px;
    background-color: #E3E3E3; }
    #content #global-left-content .padding #left-menu li a {
      display: block;
      padding: 14px 20px;
      font-size: 16px;
      line-height: 130%;
      color: #003563;
      text-align: center;
      border-bottom: none;
      font-family: Arial, sans-serif;
      text-decoration: none; }
    #content #global-left-content .padding #left-menu li ul li {
      margin: 3px 0; }
      #content #global-left-content .padding #left-menu li ul li a {
        padding: 10px 10px 10px 30px;
        background: #fff;
        font-size: 15px;
        text-align: left;
        color: #048BD3; }
        #content #global-left-content .padding #left-menu li ul li a:hover {
          color: #fff;
          background: #F3240A; }
  #content #global-left-content .padding .news {
    margin-bottom: 20px;
    background: #E3E3E3;
    border-top: 1px solid #ACACAC;
    border-left: 2px solid #ACACAC;
    border-right: 2px solid #ACACAC;
    border-bottom: 4px solid #ACACAC; }
    #content #global-left-content .padding .news div {
      margin-top: 10px;
      padding: 10px;
      background: #fff;
      border-bottom: none;
      margin-bottom: 0;
      font-family: Arial, sans-serif; }
      #content #global-left-content .padding .news div a {
        color: #F3240A;
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
        line-height: 130%; }
        #content #global-left-content .padding .news div a:hover {
          color: #DA220A; }
      #content #global-left-content .padding .news div p {
        background: none;
        text-align: justify;
        font-size: 14px;
        margin: 10px 0;
        color: #000;
        text-transform: none;
        line-height: normal;
        font-weight: 100; }
        #content #global-left-content .padding .news div p span {
          text-decoration: underline;
          font-size: 13px; }
      #content #global-left-content .padding .news div button {
        float: right;
        margin-right: 15px;
        background: url(../img/euro_button.png);
        background-size: cover;
        color: #fff;
        border: none;
        border-radius: 6px;
        width: 87px;
        height: 27px;
        padding: 5px 10px; }
        #content #global-left-content .padding .news div button:hover {
          background: #FC0808;
          background-size: cover; }
      #content #global-left-content .padding .news div:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0; }
  #content #global-left-content .padding h2 {
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    line-height: 64px;
    text-align: center;
    text-transform: uppercase;
    margin-left: -1px;
    margin-right: -1px;
    background-color: #032447; }

@media only screen and (max-width: 479px) {
  #content .right-content {
    width: 100%; }
    #content .right-content .padding {
      padding: 2% 1% 0% 1%; } }

@media only screen and (min-width: 480px) and (max-width: 599px) {
  #content .right-content {
    width: 100%; }
    #content .right-content .padding {
      padding: 2% 1% 0% 1%; } }

@media only screen and (min-width: 600px) and (max-width: 767px) {
  #content .right-content {
    width: 100%; }
    #content .right-content .padding {
      padding: 2% 1% 0% 1%; } }

@media only screen and (min-width: 768px) and (max-width: 979px) {
  #content .right-content {
    width: 100%; }
    #content .right-content .padding {
      padding: 2% 1% 0% 1%; } }

#content .right-content .top-search input {
  width: 87%;
  float: left;
  line-height: 40px;
  border-radius: 6px;
  margin: 0 2% 15px 0;
  outline: none;
  border: 1px solid #DCDCDC;
  background: #fff;
  padding-left: 5%;
  font-size: 14px; }
  @media only screen and (max-width: 479px) {
    #content .right-content .top-search input {
      width: 73%;
      margin-left: 2%;
      margin-right: 5%;
      padding-left: 2%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #content .right-content .top-search input {
      width: 82%; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #content .right-content .top-search input {
      width: 82%; } }

#content .right-content .top-search input::-moz-input-placeholder {
  color: #000; }

#content .right-content .top-search input::-webkit-input-placeholder {
  color: #000; }

#content .right-content .top-search input::-ms-input-placeholder {
  color: #000; }

#content .right-content .top-search button.search {
  width: 5%;
  float: left;
  height: 40px;
  background: transparent url(../img/button_search.png) no-repeat;
  background-size: contain;
  border: none; }
  @media only screen and (max-width: 479px) {
    #content .right-content .top-search button.search {
      width: 15%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #content .right-content .top-search button.search {
      width: 10%; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #content .right-content .top-search button.search {
      width: 10%; } }
  #content .right-content .top-search button.search:hover {
    background: url(../img/button_search_hover.png) no-repeat;
    background-size: contain; }
  #content .right-content .top-search button.search:active, #content .right-content .top-search button.search:focus {
    border: none;
    outline: none; }

#content .right-content .top-search:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

#content .right-content .history {
  background: #032447; }
  #content .right-content .history .padding {
    padding: 10px 20px; }
    #content .right-content .history .padding span {
      font-size: 15px;
      border-right: none;
      color: #7D7D7D; }
      #content .right-content .history .padding span a {
        font-size: 13px;
        color: #fff;
        padding: 4px 5px; }
        #content .right-content .history .padding span a:hover {
          color: red; }
    #content .right-content .history .padding ul li:last-child a {
      font-size: 15px;
      border-right: none;
      color: #7D7D7D; }
      #content .right-content .history .padding ul li:last-child a:hover {
        color: #7D7D7D; }
    #content .right-content .history .padding ul li {
      margin: 0;
      display: inline-block; }
      #content .right-content .history .padding ul li a {
        font-size: 13px;
        color: #fff;
        text-align: center;
        display: block;
        padding: 4px 5px; }
        #content .right-content .history .padding ul li a:hover {
          color: red; }

#content .right-content .bg-grey {
  background: #E3E3E3; }
  #content .right-content .bg-grey .padding {
    padding: 3%; }
    #content .right-content .bg-grey .padding p {
      font-size: 16px;
      font-family: Arial, sans-serif;
      font-weight: 500;
      line-height: 20px; }
    #content .right-content .bg-grey .padding ul {
      margin: 10px 0;
      counter-reset: li; }
      #content .right-content .bg-grey .padding ul > li:before, #content .right-content .bg-grey .padding ul > dt:before {
        content: counter(li);
        content-increment: li; }
      #content .right-content .bg-grey .padding ul li {
        margin: 4px 0;
        font-family: Arial, sans-serif;
        font-size: 16px;
        font-weight: 500; }
      #content .right-content .bg-grey .padding ul li:before {
        margin-right: 10px;
        background: #F20A0A;
        font-size: 2px;
        padding: 1px 2px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle; }
    #content .right-content .bg-grey .padding button {
      float: right;
      margin-right: 15px;
      background: url(../img/euro_button.png);
      background-size: cover;
      color: #fff;
      border: none;
      border-radius: 6px;
      margin: 20px 0 10px 0;
      width: 103px;
      height: 31px; }
      #content .right-content .bg-grey .padding button:hover {
        background: #FC0808;
        background-size: cover; }
    #content .right-content .bg-grey .padding:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }

#content .right-content .second-block .padding {
  padding: 2% 3%;
  font-family: Arial, sans-serif; }
  #content .right-content .second-block .padding .orange {
    text-align: center;
    color: #F26C4F;
    font-weight: bold;
    font-size: 18px; }
  #content .right-content .second-block .padding h1 {
    font-size: 17px;
    line-height: 130%;
    margin: 20px 0px 10px 0px; }
  #content .right-content .second-block .padding h2 {
    font-size: 15px;
    line-height: 130%;
    margin: 10px 0px 5px 0px; }
  #content .right-content .second-block .padding p {
    line-height: 130%;
    font-size: 13px; }
    #content .right-content .second-block .padding p span {
      font-size: 13px;
      color: #F26C4F;
      font-weight: bold; }
  #content .right-content .second-block .padding ul {
    margin: 10px 0;
    counter-reset: li; }
    #content .right-content .second-block .padding ul > li:before, #content .right-content .second-block .padding ul > dt:before {
      content: counter(li);
      content-increment: li; }
    #content .right-content .second-block .padding ul li {
      margin: 4px 0; }
    #content .right-content .second-block .padding ul li:before {
      margin-right: 10px;
      background: #F26C4F;
      color: #F26C4F;
      font-size: 2px;
      padding: 4px 4px;
      border-radius: 20%;
      display: inline-block;
      vertical-align: middle; }

#owl-demo {
  margin-bottom: 10px; }
  #owl-demo .item img {
    display: block;
    width: 100%;
    height: auto; }

.owl-theme .owl-controls .owl-buttons div {
  padding: 7px 14px; }

.owl-theme .owl-buttons i {
  margin-top: 2px; }

.owl-theme .owl-controls .owl-buttons div {
  position: absolute; }

.owl-theme .owl-controls .owl-buttons .owl-prev {
  left: 40px;
  top: 43%; }

.owl-theme .owl-controls .owl-buttons .owl-next {
  right: 40px;
  top: 43%; }

.owl-theme .owl-controls .owl-buttons div {
  font-size: 30px;
  font-weight: bold;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background: #000000;
  filter: Alpha(Opacity=60);
  opacity: 0.6; }

#footer {
  background: #103C67;
  border-top: 3px solid #617184; }
  @media only screen and (max-width: 479px) {
    #footer {
      height: 250px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #footer {
      height: 220px; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #footer {
      height: 220px; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #footer {
      height: 180px; } }
  @media only screen and (min-width: 980px) {
    #footer {
      height: 190px; } }
  #footer .padding {
    padding: 25px 1%; }
    @media only screen and (max-width: 479px) {
      #footer .padding .footer-logo {
        position: absolute;
        left: 1%;
        top: 5px;
        width: 180px;
        height: 96px; }
        #footer .padding .footer-logo img {
          max-width: 100%;
          max-height: 100%; }
      #footer .padding .wrap-footer-box {
        position: absolute;
        top: 90px;
        height: 140px;
        width: 97.9%;
        outline: 0px solid red; }
        #footer .padding .wrap-footer-box .phone {
          text-align: center;
          outline: 0px solid; }
          #footer .padding .wrap-footer-box .phone img {
            width: 30px;
            height: 20px;
            margin: 5px auto;
            display: none; }
          #footer .padding .wrap-footer-box .phone p {
            text-align: center;
            color: #fff;
            font-family: 'Calibri',sans-serif;
            font-size: 14px;
            margin: 4px 8px;
            display: inline-block; }
            #footer .padding .wrap-footer-box .phone p span {
              position: absolute;
              left: 10px;
              font-size: 13px;
              display: none; }
        #footer .padding .wrap-footer-box .address {
          text-align: center; }
          #footer .padding .wrap-footer-box .address img {
            width: 30px;
            height: 20px;
            margin: 5px auto;
            display: none; }
          #footer .padding .wrap-footer-box .address p {
            font-size: 14px;
            font-family: 'Calibri', sans-serif;
            color: #fff;
            margin-bottom: 8px; }
            #footer .padding .wrap-footer-box .address p span {
              display: inline-block;
              text-align: left;
              font-size: 12px;
              margin-right: 10px; }
        #footer .padding .wrap-footer-box .mail {
          outline: 0px solid; }
          #footer .padding .wrap-footer-box .mail img {
            width: 30px;
            height: 20px;
            margin: 5px auto;
            display: none; }
          #footer .padding .wrap-footer-box .mail p {
            text-align: center;
            font-size: 14px;
            font-family: 'Calibri', sans-serif;
            color: #fff;
            margin: 2px; }
            #footer .padding .wrap-footer-box .mail p span {
              font-size: 12px;
              margin-right: 10px;
              display: inline-block; }
      #footer .padding .buy-ukr {
        position: absolute;
        right: 1%;
        top: 5px;
        width: 70px;
        height: 141px; }
        #footer .padding .buy-ukr img {
          max-width: 100%;
          max-height: 100%; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #footer .padding .footer-logo {
        position: absolute;
        left: 1%;
        top: 5px;
        width: 200px;
        height: 96px; }
        #footer .padding .footer-logo img {
          max-width: 100%;
          max-height: 100%; }
      #footer .padding .wrap-footer-box {
        position: absolute;
        top: 90px;
        height: 105px;
        width: 97.9%;
        outline: 0px solid red; }
        #footer .padding .wrap-footer-box .phone {
          text-align: center;
          outline: 0px solid; }
          #footer .padding .wrap-footer-box .phone img {
            width: 30px;
            height: 20px;
            margin: 5px auto;
            display: none; }
          #footer .padding .wrap-footer-box .phone p {
            text-align: center;
            color: #fff;
            font-family: 'Calibri',sans-serif;
            font-size: 14px;
            margin: 4px 8px;
            display: inline-block; }
            #footer .padding .wrap-footer-box .phone p span {
              position: absolute;
              left: 10px;
              font-size: 13px;
              display: none; }
        #footer .padding .wrap-footer-box .address {
          text-align: center; }
          #footer .padding .wrap-footer-box .address img {
            width: 30px;
            height: 20px;
            margin: 5px auto;
            display: none; }
          #footer .padding .wrap-footer-box .address p {
            font-size: 14px;
            font-family: 'Calibri', sans-serif;
            color: #fff;
            margin-bottom: 8px; }
            #footer .padding .wrap-footer-box .address p span {
              display: inline-block;
              text-align: left;
              font-size: 12px;
              margin-right: 10px; }
        #footer .padding .wrap-footer-box .mail {
          outline: 0px solid; }
          #footer .padding .wrap-footer-box .mail img {
            width: 30px;
            height: 20px;
            margin: 5px auto;
            display: none; }
          #footer .padding .wrap-footer-box .mail p {
            text-align: center;
            font-size: 14px;
            font-family: 'Calibri', sans-serif;
            color: #fff;
            margin: 2px; }
            #footer .padding .wrap-footer-box .mail p span {
              font-size: 12px;
              margin-right: 10px;
              display: inline-block; }
      #footer .padding .buy-ukr {
        position: absolute;
        right: 1%;
        top: 5px;
        width: 80px;
        height: 141px; }
        #footer .padding .buy-ukr img {
          max-width: 100%;
          max-height: 100%; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #footer .padding .footer-logo {
        position: absolute;
        left: 1%;
        top: 10px;
        width: 220px;
        height: 96px; }
        #footer .padding .footer-logo img {
          max-width: 100%;
          max-height: 100%; }
      #footer .padding .wrap-footer-box {
        position: absolute;
        top: 90px;
        height: 120px;
        width: 97.9%;
        outline: 0px solid red; }
        #footer .padding .wrap-footer-box .contact {
          min-height: 50px;
          display: -moz-inline-stack;
          display: inline-block;
          vertical-align: top;
          zoom: 1;
          *display: inline;
          _height: 50px;
          padding: 1%;
          width: 30.7%; }
        #footer .padding .wrap-footer-box .phone {
          height: 110px;
          text-align: center;
          outline: 0px solid; }
          #footer .padding .wrap-footer-box .phone img {
            width: 30px;
            height: 20px;
            margin: 0; }
          #footer .padding .wrap-footer-box .phone p {
            text-align: center;
            color: #fff;
            font-family: 'Calibri',sans-serif;
            font-size: 14px;
            margin: 6px 0;
            padding-left: 0;
            position: relative; }
            #footer .padding .wrap-footer-box .phone p span {
              position: absolute;
              left: 10px;
              font-size: 13px;
              display: none; }
        #footer .padding .wrap-footer-box .address {
          height: 110px;
          text-align: center;
          border-left: 1px solid #617184;
          border-right: 1px solid #617184; }
          #footer .padding .wrap-footer-box .address img {
            width: 30px;
            height: 20px;
            margin: 0; }
          #footer .padding .wrap-footer-box .address p {
            text-align: left;
            font-size: 13px;
            font-family: 'Calibri', sans-serif;
            color: #fff;
            margin-bottom: 8px; }
            #footer .padding .wrap-footer-box .address p span {
              display: block;
              text-align: left;
              font-size: 12px;
              margin: 0; }
        #footer .padding .wrap-footer-box .mail {
          height: 110px;
          text-align: center;
          outline: 0px solid; }
          #footer .padding .wrap-footer-box .mail img {
            width: 30px;
            height: 20px;
            margin: 0; }
          #footer .padding .wrap-footer-box .mail p {
            text-align: left;
            font-size: 14px;
            font-family: 'Calibri', sans-serif;
            color: #fff;
            margin-bottom: 22px;
            padding-left: 10px; }
            #footer .padding .wrap-footer-box .mail p span {
              font-size: 12px;
              margin: 0;
              margin-right: 10px;
              display: block; }
      #footer .padding .buy-ukr {
        position: absolute;
        right: 1%;
        top: 10px;
        width: 80px;
        height: 141px; }
        #footer .padding .buy-ukr img {
          max-width: 100%;
          max-height: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #footer .padding .footer-logo {
        position: absolute;
        left: 1%;
        top: 40px;
        width: 20%;
        height: 96px; }
        #footer .padding .footer-logo img {
          max-width: 100%;
          max-height: 100%; }
      #footer .padding .wrap-footer-box {
        position: relative;
        height: 130px;
        width: 550px;
        float: right;
        margin-right: 80px;
        outline: 0px solid red; }
        #footer .padding .wrap-footer-box .phone {
          position: absolute;
          left: 1px;
          top: 0;
          width: 250px;
          height: 130px;
          text-align: center;
          outline: 0px solid; }
          #footer .padding .wrap-footer-box .phone img {
            width: 30px;
            height: 20px;
            margin: 5px auto; }
          #footer .padding .wrap-footer-box .phone p {
            text-align: center;
            color: #fff;
            font-family: 'Calibri',sans-serif;
            font-size: 14px;
            margin: 8px 0;
            padding-left: 0;
            position: relative; }
            #footer .padding .wrap-footer-box .phone p span {
              position: absolute;
              left: 10px;
              font-size: 13px;
              display: none; }
        #footer .padding .wrap-footer-box .address {
          position: absolute;
          right: 140px;
          top: 0;
          width: 180px;
          height: 130px;
          padding: 0 7px;
          text-align: center;
          border-left: 1px solid #617184;
          border-right: 1px solid #617184; }
          #footer .padding .wrap-footer-box .address img {
            width: 30px;
            height: 20px;
            margin: 5px auto; }
          #footer .padding .wrap-footer-box .address p {
            text-align: left;
            font-size: 14px;
            font-family: 'Calibri', sans-serif;
            color: #fff;
            margin-bottom: 8px; }
            #footer .padding .wrap-footer-box .address p span {
              display: block;
              text-align: left;
              font-size: 12px;
              margin: 0; }
        #footer .padding .wrap-footer-box .mail {
          position: absolute;
          right: 2px;
          top: 0;
          height: 130px;
          width: 140px;
          text-align: center;
          outline: 0px solid; }
          #footer .padding .wrap-footer-box .mail img {
            width: 30px;
            height: 20px;
            margin: 5px auto; }
          #footer .padding .wrap-footer-box .mail p {
            text-align: left;
            font-size: 14px;
            font-family: 'Calibri', sans-serif;
            color: #fff;
            margin-bottom: 22px;
            padding-left: 10px; }
            #footer .padding .wrap-footer-box .mail p span {
              font-size: 12px;
              margin: 0;
              margin-right: 10px;
              display: block; }
      #footer .padding .buy-ukr {
        position: absolute;
        right: 1%;
        top: 40px;
        width: 80px;
        height: 141px; }
        #footer .padding .buy-ukr img {
          max-width: 100%;
          max-height: 100%; } }
    @media only screen and (min-width: 980px) {
      #footer .padding .footer-logo {
        position: absolute;
        left: 1%;
        top: 20px;
        width: 263px;
        height: 96px; }
        #footer .padding .footer-logo img {
          max-width: 100%;
          max-height: 100%; }
      #footer .padding .wrap-footer-box {
        position: relative;
        height: 150px;
        width: 550px;
        float: right;
        margin-right: 150px;
        outline: 0px solid red; }
        #footer .padding .wrap-footer-box .phone {
          position: absolute;
          left: 1px;
          top: 0;
          width: 250px;
          height: 150px;
          text-align: center;
          outline: 0px solid; }
          #footer .padding .wrap-footer-box .phone img {
            width: 30px;
            height: 20px;
            margin: 5px auto; }
          #footer .padding .wrap-footer-box .phone p {
            text-align: left;
            color: #fff;
            font-family: 'Calibri',sans-serif;
            font-size: 14px;
            margin: 10px 0;
            padding-left: 50px;
            position: relative; }
            #footer .padding .wrap-footer-box .phone p span {
              position: absolute;
              left: 10px;
              font-size: 13px; }
        #footer .padding .wrap-footer-box .address {
          position: absolute;
          right: 188px;
          top: 0;
          width: 173px;
          height: 150px;
          padding: 0 5px;
          text-align: center;
          border-left: 1px solid #617184;
          border-right: 1px solid #617184; }
          #footer .padding .wrap-footer-box .address img {
            width: 30px;
            height: 20px;
            margin: 5px auto; }
          #footer .padding .wrap-footer-box .address p {
            text-align: right;
            font-size: 14px;
            font-family: 'Calibri', sans-serif;
            color: #fff; }
            #footer .padding .wrap-footer-box .address p span {
              display: block;
              text-align: left;
              font-size: 12px;
              margin: 5px 0 5px 0; }
        #footer .padding .wrap-footer-box .mail {
          position: absolute;
          right: 10px;
          top: 0;
          height: 150px;
          width: 170px;
          text-align: center; }
          #footer .padding .wrap-footer-box .mail img {
            width: 30px;
            height: 20px;
            margin: 5px auto; }
          #footer .padding .wrap-footer-box .mail p {
            text-align: left;
            font-size: 14px;
            font-family: 'Calibri', sans-serif;
            color: #fff;
            margin: 10px 0; }
            #footer .padding .wrap-footer-box .mail p span {
              font-size: 12px;
              margin: 5px 0 10px 0;
              margin-right: 10px; }
      #footer .padding .buy-ukr {
        position: absolute;
        right: 1%;
        top: 20px;
        width: 146px;
        height: 141px; }
        #footer .padding .buy-ukr img {
          max-width: 100%;
          max-height: 100%; } }

#price-table .padding p {
  text-align: center;
  font-size: 14px;
  line-height: 130%; }

#price-table .padding .model {
  text-align: center;
  margin: 15px 0 0 0;
  padding: 0;
  color: #0476C3; }

#price-table .padding .title-table {
  text-align: center;
  margin: 5px 0;
  padding: 0;
  font-size: 20px;
  color: #0C4278;
  font-weight: bold; }

@media only screen and (max-width: 479px) {
  #price-table .padding .table-img {
    text-align: center; }
    #price-table .padding .table-img img {
      max-width: 100%;
      max-height: 140px;
      background-size: cover;
      float: none;
      margin: 5px; } }

#price-table .padding .table-img {
  text-align: center; }
  #price-table .padding .table-img img {
    max-width: 100%;
    max-height: 140px;
    background-size: cover;
    margin: 5px; }

#price-table .padding table {
  width: 99%;
  margin: 10px auto;
  vertical-align: middle;
  border-collapse: collapse;
  text-align: center; }
  #price-table .padding table th {
    color: #fff;
    background: #0C4278;
    font-size: 14px;
    font-weight: 100;
    height: 45px;
    vertical-align: middle;
    border: 1px solid #000; }
    @media only screen and (max-width: 479px) {
      #price-table .padding table th {
        font-size: 12px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #price-table .padding table th {
        font-size: 13px; } }
    #price-table .padding table th td {
      padding: 5px 0; }
  #price-table .padding table tr:nth-child(odd) {
    background-color: #ccc; }
  #price-table .padding table tr:nth-child(even) {
    background-color: #fff; }
  #price-table .padding table tr {
    border: 1px solid #000; }
    #price-table .padding table tr:hover {
      background: #FF2200;
      color: #fff; }
      #price-table .padding table tr:hover a {
        color: #fff; }
    #price-table .padding table tr td {
      vertical-align: middle;
      height: 30px;
      margin: 0;
      padding: 0;
      font-size: 14px;
      border-right: 1px solid #111; }
      #price-table .padding table tr td a {
        color: #FF2200; }
      @media only screen and (max-width: 479px) {
        #price-table .padding table tr td {
          font-size: 12px; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #price-table .padding table tr td {
          font-size: 13px; } }

#price-table .padding .left-content {
  width: 49%;
  float: left; }
  #price-table .padding .left-content .padding {
    padding: 1% 0; }
  @media only screen and (max-width: 479px) {
    #price-table .padding .left-content {
      display: inline-block;
      float: none;
      width: 100%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #price-table .padding .left-content {
      display: inline-block;
      float: none;
      width: 100%; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #price-table .padding .left-content {
      display: inline-block; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    #price-table .padding .left-content {
      display: inline-block; } }

#price-table .padding .right-content {
  width: 49%;
  float: right; }
  #price-table .padding .right-content .padding {
    padding: 1% 0; }
  @media only screen and (max-width: 479px) {
    #price-table .padding .right-content {
      float: none;
      width: 100%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #price-table .padding .right-content {
      float: none;
      width: 100%; } }

#content .right-content .padding {
  padding-bottom: 1px; }

#content .right-content #uslugi .block-uslug {
  margin-bottom: 10px; }
  #content .right-content #uslugi .block-uslug h2 {
    font-size: 16px;
    padding: 0 10px;
    margin: 15px 0 5px 0;
    text-align: left; }
  #content .right-content #uslugi .block-uslug img {
    float: left;
    margin: 5px 10px;
    max-width: 100%;
    max-height: 160px;
    background-size: contain; }
  #content .right-content #uslugi .block-uslug p {
    text-align: justify; }
  @media only screen and (max-width: 479px) {
    #content .right-content #uslugi .block-uslug img {
      float: none; } }
  #content .right-content #uslugi .block-uslug:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }

#about-company .padding .svidoctvo {
  float: left;
  margin: 0 10px 10px 0; }
  #about-company .padding .svidoctvo a img {
    max-height: 300px;
    max-width: 100%; }

#about-company .padding p {
  font-size: 14px;
  line-height: 1.55;
  text-indent: 20px;
  margin: 10px 0;
  text-align: justify; }

#about-company .padding #tips {
  font-size: 14px; }

@media only screen and (max-width: 479px) {
  #about-company .padding .svidoctvo {
    float: none;
    margin: 0 10px 10px 0;
    text-align: center; }
    #about-company .padding .svidoctvo a img {
      max-height: 300px;
      max-width: 100%; }
  #about-company .padding p {
    font-size: 14px;
    font-family: Arial;
    line-height: 1.55;
    text-indent: 20px;
    margin: 10px 0;
    text-align: left; } }

@media only screen and (max-width: 479px) {
  #about-company .padding {
    /* // ul.doc */ }
    #about-company .padding ul.doc li {
      min-height: 50px;
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: top;
      zoom: 1;
      *display: inline;
      _height: 50px;
      width: 47.1%;
      min-height: 200px;
      margin: 2% 1%; }
      #about-company .padding ul.doc li a img {
        max-width: 100%;
        max-height: 360px;
        margin: 0 auto;
        background-size: contain; } }

@media only screen and (min-width: 480px) and (max-width: 599px) {
  #about-company .padding {
    /* // ul.doc */ }
    #about-company .padding ul.doc li {
      min-height: 50px;
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: top;
      zoom: 1;
      *display: inline;
      _height: 50px;
      width: 30.60%;
      min-height: 200px;
      margin: 2% 1%; }
      #about-company .padding ul.doc li a img {
        max-width: 100%;
        max-height: 360px;
        margin: 0 auto;
        background-size: contain; } }

@media only screen and (min-width: 600px) and (max-width: 767px) {
  #about-company .padding {
    /* // ul.doc */ }
    #about-company .padding ul.doc li {
      min-height: 50px;
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: top;
      zoom: 1;
      *display: inline;
      _height: 50px;
      width: 30.80%;
      min-height: 200px;
      margin: 2% 1%; }
      #about-company .padding ul.doc li a img {
        max-width: 100%;
        max-height: 360px;
        margin: 0 auto;
        background-size: contain; } }

@media only screen and (min-width: 768px) and (max-width: 979px) {
  #about-company .padding {
    /* // ul.doc */ }
    #about-company .padding ul.doc li {
      min-height: 50px;
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: top;
      zoom: 1;
      *display: inline;
      _height: 50px;
      width: 30.86%;
      min-height: 200px;
      margin: 2% 1%; }
      #about-company .padding ul.doc li a img {
        max-width: 100%;
        max-height: 360px;
        margin: 0 auto;
        background-size: contain; } }

@media only screen and (min-width: 980px) {
  #about-company .padding {
    /* // ul.doc */ }
    #about-company .padding ul.doc li {
      min-height: 50px;
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: top;
      zoom: 1;
      *display: inline;
      _height: 50px;
      width: 30.86%;
      min-height: 200px;
      margin: 2% 1%; }
      #about-company .padding ul.doc li a img {
        max-width: 100%;
        max-height: 360px;
        margin: 0 auto;
        background-size: contain; } }

.download {
  line-height: 30px;
  color: #FF2A00;
  display: block; }
  .download img {
    margin-right: 20px;
    max-height: 70px;
    max-width: 100%;
    vertical-align: middle; }

@media only screen and (max-width: 479px) {
  .download {
    line-height: 20px;
    color: #FF2A00;
    display: block;
    font-size: 12px;
    margin-bottom: 5px; }
    .download img {
      margin-right: 0;
      max-height: 30px;
      max-width: 100%;
      vertical-align: middle; } }

@media only screen and (min-width: 480px) and (max-width: 599px) {
  .download {
    line-height: 40px;
    color: #FF2A00;
    display: block;
    font-size: 13px; }
    .download img {
      margin-right: 5px;
      max-height: 40px;
      max-width: 100%;
      vertical-align: middle; } }

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .download {
    line-height: 40px;
    color: #FF2A00;
    display: block;
    font-size: 14px; }
    .download img {
      margin-right: 20px;
      max-height: 40px;
      max-width: 100%;
      vertical-align: middle; } }

@media only screen and (min-width: 768px) and (max-width: 979px) {
  .download {
    line-height: 50px;
    color: #FF2A00;
    display: block;
    font-size: 14px; }
    .download img {
      margin-right: 20px;
      max-height: 50px;
      max-width: 100%;
      vertical-align: middle; } }

.right-content .padding {
  padding-bottom: 1px; }
  .right-content .padding #production .pod-plitku {
    width: 100%;
    text-align: center; }
    .right-content .padding #production .pod-plitku li {
      margin: 1% 0.5% 2% 0.5%;
      width: 47%;
      min-height: 50px;
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: top;
      zoom: 1;
      *display: inline;
      _height: 50px; }
      .right-content .padding #production .pod-plitku li .category {
        margin: 0;
        display: block;
        text-align: center; }
        .right-content .padding #production .pod-plitku li .category img {
          max-width: 100%;
          max-height: 260px;
          background-size: contain;
          -moz-box-shadow: 0px 0px 16px #4F6477;
          -webkit-box-shadow: 0px 0px 16px #4F6477;
          box-shadow: 0px 0px 16px #4F6477; }
      .right-content .padding #production .pod-plitku li a {
        text-align: center;
        display: block;
        font-size: 18px;
        color: #F3240A;
        font-weight: 600;
        margin-top: 10px; }
        .right-content .padding #production .pod-plitku li a:hover {
          color: #D52008; }
  @media only screen and (max-width: 479px) {
    .right-content .padding #production .pod-plitku li a {
      font-size: 13px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .right-content .padding #production .pod-plitku li a {
      font-size: 14px; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .right-content .padding #production .pod-plitku li a {
      font-size: 16px; } }

.right-content .padding {
  padding-bottom: 1px; }
  .right-content .padding #luki-pod-plitku .pod-plitku {
    width: 100%;
    text-align: center; }
    .right-content .padding #luki-pod-plitku .pod-plitku li {
      margin: 1% 0.5% 2% 0.5%;
      width: 47%;
      min-height: 50px;
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: top;
      zoom: 1;
      *display: inline;
      _height: 50px; }
      .right-content .padding #luki-pod-plitku .pod-plitku li .category {
        margin: 0;
        display: block;
        text-align: center; }
        .right-content .padding #luki-pod-plitku .pod-plitku li .category img {
          max-width: 100%;
          max-height: 260px;
          background-size: contain;
          -moz-box-shadow: 0px 0px 16px #4F6477;
          -webkit-box-shadow: 0px 0px 16px #4F6477;
          box-shadow: 0px 0px 16px #4F6477; }
      .right-content .padding #luki-pod-plitku .pod-plitku li a {
        text-align: center;
        display: block;
        font-size: 18px;
        color: #F3240A;
        font-weight: 600;
        margin-top: 10px; }
        .right-content .padding #luki-pod-plitku .pod-plitku li a:hover {
          color: #D52008; }
  @media only screen and (max-width: 479px) {
    .right-content .padding #luki-pod-plitku .pod-plitku li a {
      font-size: 13px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .right-content .padding #luki-pod-plitku .pod-plitku li a {
      font-size: 14px; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .right-content .padding #luki-pod-plitku .pod-plitku li a {
      font-size: 16px; } }

.right-content .padding {
  padding-bottom: 1px; }
  .right-content .padding #luki-pod-pokrasku .pod-pokrasku {
    width: 100%;
    text-align: center; }
    .right-content .padding #luki-pod-pokrasku .pod-pokrasku li {
      margin: 1% 0.5% 2% 0.5%;
      width: 47%;
      min-height: 50px;
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: top;
      zoom: 1;
      *display: inline;
      _height: 50px; }
      .right-content .padding #luki-pod-pokrasku .pod-pokrasku li .category {
        margin: 0;
        display: block;
        text-align: center; }
        .right-content .padding #luki-pod-pokrasku .pod-pokrasku li .category img {
          max-width: 100%;
          max-height: 260px;
          background-size: contain;
          -moz-box-shadow: 0px 0px 16px #4F6477;
          -webkit-box-shadow: 0px 0px 16px #4F6477;
          box-shadow: 0px 0px 16px #4F6477; }
      .right-content .padding #luki-pod-pokrasku .pod-pokrasku li a {
        text-align: center;
        display: block;
        font-size: 18px;
        color: #F3240A;
        font-weight: 600;
        margin-top: 10px; }
        .right-content .padding #luki-pod-pokrasku .pod-pokrasku li a:hover {
          color: #D52008; }
  @media only screen and (max-width: 479px) {
    .right-content .padding #luki-pod-pokrasku .pod-pokrasku li a {
      font-size: 13px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .right-content .padding #luki-pod-pokrasku .pod-pokrasku li a {
      font-size: 14px; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .right-content .padding #luki-pod-pokrasku .pod-pokrasku li a {
      font-size: 16px; } }

.right-content .padding {
  padding-bottom: 1px; }
  .right-content .padding #napolni-luki .pod-plitku {
    width: 100%;
    text-align: center; }
    .right-content .padding #napolni-luki .pod-plitku li {
      margin: 1% 0.5% 2% 0.5%;
      width: 47%;
      min-height: 50px;
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: top;
      zoom: 1;
      *display: inline;
      _height: 50px; }
      .right-content .padding #napolni-luki .pod-plitku li .category {
        margin: 0;
        display: block;
        text-align: center; }
        .right-content .padding #napolni-luki .pod-plitku li .category img {
          max-width: 100%;
          max-height: 260px;
          background-size: contain;
          -moz-box-shadow: 0px 0px 16px #4F6477;
          -webkit-box-shadow: 0px 0px 16px #4F6477;
          box-shadow: 0px 0px 16px #4F6477; }
      .right-content .padding #napolni-luki .pod-plitku li a {
        text-align: center;
        display: block;
        font-size: 18px;
        color: #F3240A;
        font-weight: 600;
        margin-top: 10px; }
        .right-content .padding #napolni-luki .pod-plitku li a:hover {
          color: #D52008; }
  @media only screen and (max-width: 479px) {
    .right-content .padding #napolni-luki .pod-plitku li a {
      font-size: 13px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .right-content .padding #napolni-luki .pod-plitku li a {
      font-size: 14px; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .right-content .padding #napolni-luki .pod-plitku li a {
      font-size: 16px; } }

.right-content .padding {
  padding-bottom: 1px; }
  .right-content .padding #individualni-luki .pod-individual {
    width: 100%; }
    .right-content .padding #individualni-luki .pod-individual li {
      margin: 1% 0.5% 2% 0.5%;
      width: 31.5%;
      min-height: 50px;
      display: -moz-inline-stack;
      display: inline-block;
      vertical-align: top;
      zoom: 1;
      *display: inline;
      _height: 50px; }
      .right-content .padding #individualni-luki .pod-individual li .category {
        margin: 0;
        display: block;
        text-align: center;
        padding: 5px 5px 0 5px;
        background: #022243;
        font-size: 18px;
        color: #fff; }
        .right-content .padding #individualni-luki .pod-individual li .category:hover {
          background: #E62209; }
        .right-content .padding #individualni-luki .pod-individual li .category img {
          margin: 0 auto 15px auto;
          max-width: 100%;
          max-height: 260px;
          background-size: contain;
          -moz-box-shadow: 0px 0px 16px #4F6477;
          -webkit-box-shadow: 0px 0px 16px #4F6477;
          box-shadow: 0px 0px 16px #4F6477; }
      .right-content .padding #individualni-luki .pod-individual li .price-ind {
        padding: 10px 0;
        text-align: center;
        font-size: 15px;
        font-style: italic;
        background: #ccc;
        border-bottom: 2px solid #A1A1A1;
        color: #111;
        margin: 15px -5px 0 -5px; }
  @media only screen and (max-width: 479px) {
    .right-content .padding #individualni-luki .pod-individual {
      width: 100%;
      text-align: center; }
      .right-content .padding #individualni-luki .pod-individual li {
        text-align: center;
        margin: 1% 0.5% 2% 0.5%;
        width: 100%;
        min-height: 50px;
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: top;
        zoom: 1;
        *display: inline;
        _height: 50px; }
        .right-content .padding #individualni-luki .pod-individual li .category {
          margin: 0;
          display: block;
          text-align: center;
          padding: 5px 5px 0 5px;
          background: #022243;
          font-size: 14px;
          color: #fff; }
          .right-content .padding #individualni-luki .pod-individual li .category:hover {
            background: #E62209; }
          .right-content .padding #individualni-luki .pod-individual li .category img {
            display: block;
            margin-bottom: 15px;
            max-width: 100%;
            max-height: 260px;
            background-size: contain;
            -moz-box-shadow: 0px 0px 16px #4F6477;
            -webkit-box-shadow: 0px 0px 16px #4F6477;
            box-shadow: 0px 0px 16px #4F6477; }
        .right-content .padding #individualni-luki .pod-individual li .price-ind {
          padding: 10px 0;
          text-align: center;
          font-size: 13px;
          font-style: italic;
          background: #ccc;
          border-bottom: 2px solid #A1A1A1;
          color: #111;
          margin: 15px -5px 0 -5px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .right-content .padding #individualni-luki .pod-individual {
      width: 100%;
      text-align: center; }
      .right-content .padding #individualni-luki .pod-individual li {
        margin: 1% 0.5% 2% 0.5%;
        width: 48%;
        min-height: 50px;
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: top;
        zoom: 1;
        *display: inline;
        _height: 50px; }
        .right-content .padding #individualni-luki .pod-individual li .category {
          margin: 0;
          display: block;
          text-align: center;
          padding: 5px 5px 0 5px;
          background: #022243;
          font-size: 15px;
          color: #fff; }
          .right-content .padding #individualni-luki .pod-individual li .category:hover {
            background: #E62209; }
          .right-content .padding #individualni-luki .pod-individual li .category img {
            margin-bottom: 15px;
            max-width: 100%;
            max-height: 260px;
            background-size: contain;
            -moz-box-shadow: 0px 0px 16px #4F6477;
            -webkit-box-shadow: 0px 0px 16px #4F6477;
            box-shadow: 0px 0px 16px #4F6477; }
        .right-content .padding #individualni-luki .pod-individual li .price-ind {
          padding: 10px 0;
          text-align: center;
          font-size: 14px;
          font-style: italic;
          background: #ccc;
          border-bottom: 2px solid #A1A1A1;
          color: #111;
          margin: 15px -5px 0 -5px; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .right-content .padding #individualni-luki .pod-individual {
      width: 100%;
      text-align: center; }
      .right-content .padding #individualni-luki .pod-individual li {
        margin: 1% 0.5% 2% 0.5%;
        width: 31.5%;
        min-height: 50px;
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: top;
        zoom: 1;
        *display: inline;
        _height: 50px; }
        .right-content .padding #individualni-luki .pod-individual li .category {
          margin: 0;
          display: block;
          text-align: center;
          padding: 5px 5px 0 5px;
          background: #022243;
          font-size: 15px;
          color: #fff; }
          .right-content .padding #individualni-luki .pod-individual li .category:hover {
            background: #E62209; }
          .right-content .padding #individualni-luki .pod-individual li .category img {
            margin-bottom: 15px;
            max-width: 100%;
            max-height: 260px;
            background-size: contain;
            -moz-box-shadow: 0px 0px 16px #4F6477;
            -webkit-box-shadow: 0px 0px 16px #4F6477;
            box-shadow: 0px 0px 16px #4F6477; }
          .right-content .padding #individualni-luki .pod-individual li .category p {
            display: block;
            width: 100%; }
        .right-content .padding #individualni-luki .pod-individual li .price-ind {
          padding: 10px 0;
          text-align: center;
          font-size: 14px;
          font-style: italic;
          background: #ccc;
          border-bottom: 2px solid #A1A1A1;
          color: #111;
          margin: 15px -5px 0 -5px; } }

.right-content .padding {
  padding-bottom: 1px; }
  .right-content .padding #model h2.title {
    padding-bottom: 0; }
  .right-content .padding #model .left-content-inner {
    width: 48%;
    float: left; }
    .right-content .padding #model .left-content-inner .padding {
      padding: 0 1%; }
    @media only screen and (max-width: 479px) {
      .right-content .padding #model .left-content-inner {
        display: block;
        float: none;
        width: 100%; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      .right-content .padding #model .left-content-inner {
        display: block;
        float: none;
        width: 100%; } }
  .right-content .padding #model .right-content-inner {
    width: 48%;
    float: right; }
    @media only screen and (max-width: 479px) {
      .right-content .padding #model .right-content-inner {
        float: none;
        width: 100%; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      .right-content .padding #model .right-content-inner {
        float: none;
        width: 100%; } }
    .right-content .padding #model .right-content-inner .padding {
      padding: 0 1%; }
      @media only screen and (max-width: 479px) {
        .right-content .padding #model .right-content-inner .padding {
          padding: 3% 0 3% 3%; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        .right-content .padding #model .right-content-inner .padding {
          padding: 3% 0 3% 5%; } }
      @media only screen and (max-width: 479px) {
        .right-content .padding #model .right-content-inner .padding {
          display: block;
          float: none;
          width: 100%; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        .right-content .padding #model .right-content-inner .padding {
          display: block;
          float: none;
          width: 100%; } }
      .right-content .padding #model .right-content-inner .padding ul {
        list-style-type: square;
        line-height: 1.55;
        margin: 5px 0 15px 5px; }
      .right-content .padding #model .right-content-inner .padding p {
        font-size: 14px;
        line-height: 130%;
        margin-bottom: 5px; }
      .right-content .padding #model .right-content-inner .padding p.price {
        width: 170px;
        text-align: center;
        line-height: 1.55;
        padding: 5px 0px;
        background: #ccc;
        display: inline-block;
        margin: 15px 10px 15px 0; }
        .right-content .padding #model .right-content-inner .padding p.price span {
          font-size: 16px;
          font-weight: 600;
          color: #032447;
          margin: 0 5px; }
        @media only screen and (max-width: 479px) {
          .right-content .padding #model .right-content-inner .padding p.price {
            display: block;
            width: 70%;
            margin: 10px auto; } }
      .right-content .padding #model .right-content-inner .padding a.zakazat {
        width: 170px;
        text-align: center;
        display: inline-block;
        font-size: 17px;
        padding: 7px 0px;
        font-weight: bold;
        color: #fff;
        background: #032447;
        border: none;
        margin: 15px 0 15px 0; }
        .right-content .padding #model .right-content-inner .padding a.zakazat:hover {
          background: #FF2A00; }
        @media only screen and (max-width: 479px) {
          .right-content .padding #model .right-content-inner .padding a.zakazat {
            display: block;
            width: 70%;
            margin: 10px auto; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .right-content .padding #model {
      display: block;
      float: none;
      width: 100%; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .right-content .padding #model {
      display: block; } }
  .right-content .padding .responsive-tabs {
    margin-top: 10px;
    margin-bottom: 10px; }
  @media only screen and (max-width: 479px) {
    .right-content .padding .resp-tab-content {
      padding: 5px; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .right-content .padding .resp-tab-content {
      padding: 10px; } }
  .right-content .padding .resp-tabs-container p {
    font-size: 14px;
    line-height: 1.3;
    text-align: left; }
    @media only screen and (max-width: 479px) {
      .right-content .padding .resp-tabs-container p {
        font-size: 12px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      .right-content .padding .resp-tabs-container p {
        font-size: 13px; } }
  .right-content .padding .resp-tabs-container ul {
    margin: 5px 0px 10px 20px; }
  .right-content .padding .resp-tabs-container ul li {
    text-align: left;
    font-size: 14px;
    line-height: 1.3;
    list-style-type: circle; }
    @media only screen and (max-width: 479px) {
      .right-content .padding .resp-tabs-container ul li {
        font-size: 12px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      .right-content .padding .resp-tabs-container ul li {
        font-size: 13px; } }
  .right-content .padding .resp-tabs-container .shema {
    font-weight: bold;
    margin: 10px 0;
    text-align: center; }
  .right-content .padding .resp-tabs-container .rest-tabs-img {
    text-align: center;
    padding: 5px; }
    .right-content .padding .resp-tabs-container .rest-tabs-img img {
      max-height: 100%;
      max-width: 100%;
      background-size: contain; }
  .right-content .padding .opis-tab {
    text-align: center; }
    .right-content .padding .opis-tab p {
      text-align: left;
      margin-bottom: 5px; }
    .right-content .padding .opis-tab img {
      max-width: 100%;
      margin: 5px; }

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: 1;
  -webkit-transition: opacity .5s;
  -moz-transition: opacity .5s;
  -ms-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity .5s; }

.overlay:target {
  visibility: visible; }

.popup {
  width: 400px;
  min-height: 250px;
  display: inline-block;
  position: fixed;
  visibility: hidden;
  z-index: 10;
  left: 50%;
  background: #fff;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  -moz-box-shadow: -2px 3px 18px #000000;
  -webkit-box-shadow: -2px 3px 18px #000000;
  box-shadow: -2px 3px 18px #000000; }
  @media only screen and (max-width: 479px) {
    .popup {
      width: 95%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .popup {
      width: 90%; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .popup {
      width: 80%; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    .popup {
      width: 70%; } }
  .popup .padding {
    padding: 10px; }
  .popup h1.title {
    margin: 0 0 10px 0;
    padding: 5px 15px; }
  .popup p {
    margin-bottom: 10px; }
  .popup label {
    font-size: 15px;
    margin-right: 20px;
    display: block;
    color: #111; }
  .popup input, .popup textarea {
    width: 90%;
    margin: 2px 0 5px 0;
    height: 24px;
    line-height: 24px;
    vertical-align: middle;
    font-size: 14px;
    border: 1px solid #111;
    background: none;
    padding: 0 10px 0 15px;
    border-radius: 6px; }
    .popup input:focus, .popup textarea:focus {
      background: #fff;
      border: 1px solid red;
      border-radius: 10px;
      outline: none; }
  .popup textarea#zapros {
    width: 90% !important; }
  .popup button.send {
    width: 100px;
    padding: 6px 0;
    text-align: center;
    font-size: 16px;
    background: red;
    color: #fff;
    margin: 5px 0 0 0px;
    border-radius: 8px;
    border: none; }
    .popup button.send:hover {
      border-radius: 10px; }

.overlay:target + .popup {
  top: 50%;
  opacity: 1;
  visibility: visible; }
  @media only screen and (max-width: 479px) {
    .overlay:target + .popup {
      top: 35%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .overlay:target + .popup {
      top: 33%; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    .overlay:target + .popup {
      top: 30%; } }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    .overlay:target + .popup {
      top: 35%; } }

.close:before {
  color: #fff;
  content: "X";
  font-size: 24px; }

.close {
  background-color: rgba(4, 106, 249, 0.87);
  height: 35px;
  line-height: 33px;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0px;
  width: 50px;
  border-radius: 0px 10px 0 10px;
  -webkit-border-radius: 0px 10px 0 10px;
  -moz-border-radius: 0px 10px 0 10px;
  -ms-border-radius: 0px 10px 0 10px;
  -o-border-radius: 0px 10px 0 10px; }
  .close:hover {
    background-color: red; }

/*надо настроить*/
#content .padding {
  padding-bottom: 1px; }

#contacty h1.title {
  padding: 10px 10px;
  margin: 10px 0; }

#contacty .left-content {
  width: 59%;
  margin: 5px 0; }
  @media only screen and (max-width: 479px) {
    #contacty .left-content {
      width: 100%;
      float: none; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #contacty .left-content {
      width: 100%;
      float: none; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #contacty .left-content {
      width: 100%;
      float: none; } }

#contacty .right-content {
  width: 39%;
  margin: 5px 0; }
  @media only screen and (max-width: 479px) {
    #contacty .right-content {
      width: 100%;
      float: none; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    #contacty .right-content {
      width: 100%;
      float: none; } }
  @media only screen and (min-width: 600px) and (max-width: 767px) {
    #contacty .right-content {
      width: 100%;
      float: none; } }

#contacty .left-content .padding, #contacty .right-content .padding {
  padding: 10px; }

#contacty .shadow {
  border-radius: 15px;
  padding: 10px;
  -moz-box-shadow: 0px 1px 19px #a8a8a8;
  -webkit-box-shadow: 0px 1px 19px #a8a8a8;
  box-shadow: 0px 1px 19px #a8a8a8; }

#contacty .markets {
  width: 49%;
  min-height: 50px;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
  *display: inline;
  _height: 50px; }
  #contacty .markets .padding {
    padding: 10px; }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #contacty .markets .padding {
        padding: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      #contacty .markets .padding {
        padding: 0; } }
  @media only screen and (max-width: 479px) {
    #contacty .markets {
      width: 100%; } }

#contacty .second p {
  text-align: right; }

#contacty .markets h2.blue-color {
  font-size: 16px;
  padding: 5px 0;
  font-weight: bold;
  border-bottom: 1px solid red;
  margin: 10px 3px; }

#contacty .markets p {
  line-height: 1.55; }

#contacty .markets p.work-table {
  font-size: 13px;
  font-weight: bold;
  border-bottom: 1px solid #111;
  margin: 5px 5px 0 5px; }

#contacty .markets p.mail a {
  font-weight: bold;
  color: red; }
  #contacty .markets p.mail a:hover {
    color: #BE0000; }

#contacty .form form {
  padding-right: 10px; }

#contacty .form p {
  line-height: 1.30;
  text-align: center;
  margin: 10px 0; }

#contacty .form label {
  font-size: 14px;
  margin-right: 20px; }

#contacty .form input {
  width: 92%;
  margin: 5px 0 10px 0;
  height: 25px;
  vertical-align: middle;
  background: none;
  border: 1px solid #111;
  border-radius: 4px;
  line-height: 25px;
  padding-left: 15px;
  font-size: 16px;
  padding-right: 10px; }
  #contacty .form input:focus {
    background: #fff;
    outline: none;
    border: 1px solid red;
    border-radius: 6px; }

#contacty .form textarea {
  width: 92%;
  background: none;
  border: 1px solid #111;
  padding-left: 15px;
  padding-right: 10px;
  border-radius: 4px; }
  #contacty .form textarea:focus {
    background: #fff;
    outline: none;
    border: 1px solid red;
    border-radius: 6px; }

#contacty .form button.send {
  width: 120px;
  margin: 15px 10px 0 0;
  font-size: 14px;
  text-align: center;
  padding: 6px 0;
  border-radius: 8px;
  border: 1px solid #ccc;
  float: right; }
  #contacty .form button.send:active {
    outline: none; }

#contacty #map {
  margin: 20px 0; }

#photo .list-photo {
  width: 100%;
  text-align: center; }
  #photo .list-photo li {
    margin: 1% 0.5% 2% 0.5%;
    width: 47%;
    min-height: 50px;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
    _height: 50px; }
    #photo .list-photo li .category {
      margin: 0;
      display: block;
      text-align: center; }
      #photo .list-photo li .category img {
        max-width: 100%;
        max-height: 260px;
        background-size: contain;
        -moz-box-shadow: 0px 0px 16px #4F6477;
        -webkit-box-shadow: 0px 0px 16px #4F6477;
        box-shadow: 0px 0px 16px #4F6477; }
    #photo .list-photo li .title-list {
      text-align: center;
      display: block;
      font-size: 18px;
      color: #F3240A;
      font-weight: 600;
      margin-top: 10px;
      border-bottom: 1px solid red; }
      #photo .list-photo li .title-list:hover {
        color: #D52008; }
      @media only screen and (max-width: 479px) {
        #photo .list-photo li .title-list {
          font-size: 13px; } }
      @media only screen and (min-width: 480px) and (max-width: 599px) {
        #photo .list-photo li .title-list {
          font-size: 14px; } }
      @media only screen and (min-width: 600px) and (max-width: 767px) {
        #photo .list-photo li .title-list {
          font-size: 15px; } }

@media only screen and (max-width: 479px) {
  #photo .list-photo li a {
    font-size: 13px; } }

@media only screen and (min-width: 480px) and (max-width: 599px) {
  #photo .list-photo li a {
    font-size: 14px; } }

@media only screen and (min-width: 600px) and (max-width: 767px) {
  #photo .list-photo li a {
    font-size: 16px; } }

#photo-galery .photo {
  width: 100%;
  text-align: center; }
  #photo-galery .photo li {
    margin: 1% 0.5% 1% 0.5%;
    width: 23.40%;
    min-height: 50px;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
    _height: 50px; }
    #photo-galery .photo li .category {
      margin: 0;
      display: block;
      text-align: center; }
      #photo-galery .photo li .category img {
        border: 2px solid #ccc;
        max-width: 100%;
        max-height: 260px;
        background-size: contain;
        -moz-box-shadow: 0px 0px 16px #4F6477;
        -webkit-box-shadow: 0px 0px 16px #4F6477;
        box-shadow: 0px 0px 16px #4F6477; }
    @media only screen and (max-width: 479px) {
      #photo-galery .photo li {
        width: 43%; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #photo-galery .photo li {
        width: 43%; } }

#video-galery .video {
  width: 100%;
  text-align: center; }
  #video-galery .video li {
    margin: 1% 0.5% 1% 0.5%;
    width: 48%;
    min-height: 50px;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
    _height: 50px; }
    @media only screen and (max-width: 479px) {
      #video-galery .video li {
        width: 100%; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      #video-galery .video li {
        width: 100%; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      #video-galery .video li {
        width: 100%; } }

/*надо настроить*/
#news h1.title {
  text-align: left; }

#news .text-news:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

#news .text-news p {
  line-height: 1.55; }

#news .text-news a.detail-news {
  float: right;
  margin-right: 15px;
  background: url(../img/euro_button.png);
  background-size: cover;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 15px; }
  #news .text-news a.detail-news:hover {
    background: #FC0808;
    background-size: cover; }

#news .text-news button {
  float: right;
  margin-right: 15px;
  background: url(../img/euro_button.png);
  background-size: cover;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 15px; }
  #news .text-news button:hover {
    background: #FC0808;
    background-size: cover; }

.left {
  float: left; }

.right {
  float: right; }

.mp-o {
  margin: 2px 0;
  padding: 2px 0; }

.center {
  text-align: center; }

.block {
  width: 100%;
  box-sizing: content-box; }
  .block .pod-block {
    margin: 0 auto;
    position: relative;
    box-sizing: content-box; }
    @media only screen and (max-width: 479px) {
      .block .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      .block .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      .block .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      .block .pod-block {
        width: 100%; } }
    @media only screen and (min-width: 980px) {
      .block .pod-block {
        max-width: 1350px; } }

.left-content {
  width: 29%;
  float: left; }
  .left-content .padding {
    padding: 2% 1% 0% 3%; }
  @media only screen and (max-width: 479px) {
    .left-content {
      float: none;
      width: 100%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .left-content {
      float: none;
      width: 100%; } }

.right-content {
  width: 69%;
  float: right; }
  .right-content .padding {
    padding: 2% 0% 0% 1%; }
  @media only screen and (max-width: 479px) {
    .right-content {
      float: none;
      width: 100%; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .right-content {
      float: none;
      width: 100%; } }
  .right-content p {
    line-height: 130%; }

span.skype_c2c_container img.skype_c2c_logo_img {
  display: none !important; }

#bg-body {
  background: -webkit-linear-gradient(top, #fafafa 0%, #bdd7f0 55%, #a4bad1 99%);
  background: -moz-linear-gradient(top, #fafafa 0%, #bdd7f0 55%, #a4bad1 99%);
  background: -o-linear-gradient(top, #fafafa 0%, #bdd7f0 55%, #a4bad1 99%);
  background: -ms-linear-gradient(top, #fafafa 0%, #bdd7f0 55%, #a4bad1 99%);
  background: linear-gradient(to bottom, #fafafa 0%, #bdd7f0 55%, #a4bad1 99%); }

.clear {
  clear: both; }

h1.title {
  color: #032447;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  padding: 15px 10px;
  margin: 15px 0; }

h2.title {
  color: #032447;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  padding: 15px 10px;
  margin: 15px 0; }

h3.left-news {
  font-size: 16px;
  color: #292929;
  font-weight: bold;
  line-height: 130%;
  text-align: center;
  text-transform: none;
  margin-top: 10px;
  background-color: none; }

.blue-line {
  border-bottom: 1px solid #0476C3; }

h2.blue-color {
  color: #0476C3;
  text-align: center;
  margin: 15px 0;
  font-weight: 100; }

.line {
  height: 3px;
  width: 100%;
  background: #ACACAC;
  margin: 0 0 5px 0; }

.gallery-slider {
  border-top: 1px solid #ACACAC;
  border-left: 2px solid #ACACAC;
  border-right: 2px solid #ACACAC;
  border-bottom: 4px solid #ACACAC; }
  .gallery-slider .prev-btnOFF {
    position: absolute;
    top: 43%;
    left: 2%;
    padding: 25px;
    z-index: 9999;
    background: url("../img/prev.png") no-repeat 100% 100%;
    background-size: cover;
    cursor: pointer;
    box-sizing: border-box; }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      .gallery-slider .prev-btnOFF {
        padding: 15px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      .gallery-slider .prev-btnOFF {
        display: none; } }
  .gallery-slider .next-btnOFF {
    position: absolute;
    top: 43%;
    right: 2%;
    padding: 25px;
    z-index: 9999;
    background: url("../img/next.png") no-repeat 100% 100%;
    background-size: cover;
    cursor: pointer;
    box-sizing: border-box; }
    @media only screen and (min-width: 600px) and (max-width: 767px) {
      .gallery-slider .next-btnOFF {
        padding: 15px; } }
    @media only screen and (min-width: 480px) and (max-width: 599px) {
      .gallery-slider .next-btnOFF {
        display: none; } }

.gallery-slider {
  width: 95%;
  margin: 10px auto; }

.gallery-slider2 {
  width: 100%;
  margin: 10px auto; }

.gallery-slider2 img {
  width: 100%; }

.gallery-slider-main {
  width: 100%;
  margin: 0 auto;
  background: #e6e7e7; }
  .gallery-slider-main .slider-for-main {
    max-width: 1200px;
    margin: 0 auto; }

.gallery-slider-main img {
  width: 100%; }

.slider-nav2, .slider-nav-main {
  display: none; }

.slider-nav {
  padding: 0 80px;
  background: linear-gradient(to bottom, #fff, #D0D0D0); }
  @media only screen and (max-width: 479px) {
    .slider-nav {
      padding: 0; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .slider-nav {
      padding: 0; } }

.slider-nav img {
  margin: 10px;
  padding: 4px;
  border: 1px solid #aaa; }
  @media only screen and (max-width: 479px) {
    .slider-nav img {
      margin: 0; } }
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    .slider-nav img {
      margin: 0; } }
