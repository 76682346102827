.right-content{
	.padding{ padding-bottom: 1px;

		
	#individualni-luki{
		 		.pod-individual{
			 width: 100%; 
				li{	
					margin: 1% 0.5% 2% 0.5%;
					width: 31.5%;
					@include pod-block-adaptive;

					.category{ margin: 0; display: block; text-align: center; padding: 5px 5px 0 5px; background: #022243; font-size: 18px; color: #fff;
						&:hover{background: #E62209;}
						img{ margin: 0 auto 15px auto; max-width: 100%; max-height: 260px; background-size: contain; -moz-box-shadow: 0px 0px 16px #4F6477; -webkit-box-shadow: 0px 0px 16px #4F6477; box-shadow: 0px 0px 16px #4F6477;}
					}
					.price-ind { padding: 10px 0; text-align: center; font-size: 15px; font-style: italic; background: #ccc; border-bottom: 2px solid #A1A1A1; color: #111; margin: 15px -5px 0 -5px;}
				}
			}
		 }//#individualni-luki




		 // Для телефонов
		 @include respond-to(handhelds) { 
		 	#individualni-luki{
		 		.pod-individual{ width: 100%; text-align: center;
		 			li{	 text-align: center;
		 				margin: 1% 0.5% 2% 0.5%;
		 				width: 100%;
		 				@include pod-block-adaptive;

		 				.category{ margin: 0; display: block; text-align: center; padding: 5px 5px 0 5px; background: #022243; font-size: 14px; color: #fff;
		 					&:hover{background: #E62209;}
		 					img{ display: block; margin-bottom: 15px; max-width: 100%; max-height: 260px; background-size: contain; -moz-box-shadow: 0px 0px 16px #4F6477; -webkit-box-shadow: 0px 0px 16px #4F6477; box-shadow: 0px 0px 16px #4F6477;}
		 				}
		 				.price-ind { padding: 10px 0; text-align: center; font-size: 13px; font-style: italic; background: #ccc; border-bottom: 2px solid #A1A1A1; color: #111; margin: 15px -5px 0 -5px;}
		 			}
		 		}
		 	}//#individualni-luki
		 }


		 // Для телефонов с широким экраном
		 @include respond-to(wide-handhelds) {
			#individualni-luki{
				.pod-individual{ width: 100%; text-align: center;
					li{	
						margin: 1% 0.5% 2% 0.5%;
						width: 48%;
						@include pod-block-adaptive;

						.category{ margin: 0; display: block; text-align: center; padding: 5px 5px 0 5px; background: #022243; font-size: 15px; color: #fff;
							&:hover{background: #E62209;}
							img{ margin-bottom: 15px; max-width: 100%; max-height: 260px; background-size: contain; -moz-box-shadow: 0px 0px 16px #4F6477; -webkit-box-shadow: 0px 0px 16px #4F6477; box-shadow: 0px 0px 16px #4F6477;}
						}
						.price-ind { padding: 10px 0; text-align: center; font-size: 14px; font-style: italic; background: #ccc; border-bottom: 2px solid #A1A1A1; color: #111; margin: 15px -5px 0 -5px;}
					}
				}
			}//#individualni-luki
		 }


		 // Для маленьких планшетов
		 @include respond-to(small-tablets) { 
			#individualni-luki{
				.pod-individual{ width: 100%; text-align: center;
					li{	
						margin: 1% 0.5% 2% 0.5%;
						width: 31.5%;
						@include pod-block-adaptive;

						.category{ margin: 0; display: block; text-align: center; padding: 5px 5px 0 5px; background: #022243; font-size: 15px; color: #fff;
							&:hover{background: #E62209;}
							img{ margin-bottom: 15px; max-width: 100%; max-height: 260px; background-size: contain; -moz-box-shadow: 0px 0px 16px #4F6477; -webkit-box-shadow: 0px 0px 16px #4F6477; box-shadow: 0px 0px 16px #4F6477;}
							p {display: block; width:100%;}
						}
						.price-ind {padding: 10px 0; text-align: center; font-size: 14px; font-style: italic; background: #ccc; border-bottom: 2px solid #A1A1A1; color: #111; margin: 15px -5px 0 -5px;}
					}
				}
			}//#individualni-luki
		 }


	} // .padding
} //.right-content




