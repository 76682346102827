@font-face {
    font-family: "Calibri";
    src: url("../fonts/Calibri.otf");
    src: url("../fonts/Calibri.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* @font-face {
    font-family: "franklin-gothic-medium";
    src: url("../fonts/franklin-gothic-medium.otf");
    src: url("../fonts/franklin-gothic-medium.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
} */

$calibri : Arial;
/* $calibri : 'Calibri'; */
/* $gothic : 'franklin-gothic-medium'; */
$gothic : Arial;

body{font-family: Arial, sans-serif;}

// Создаем mixin
@mixin respond-to($media) {
  @if $media == handhelds {
    @media only screen and (max-width: 479px) { @content; }
  }
  @else if $media == wide-handhelds {
    @media only screen and (min-width: 480px) and (max-width: 599px) { @content; }
  }
  @else if $media == small-tablets {
    @media only screen and (min-width: 600px) and (max-width: 767px) { @content; }
  }  
  @else if $media == tablets {
    @media only screen and (min-width: 768px) and (max-width: 979px) { @content; }
  }
  @else if $media == display {
    @media only screen and (min-width: 980px) { @content; }
  }
}

@mixin clearfix { &:after {  content: ".";  display: block;  clear: both;  visibility: hidden;  line-height: 0;  height: 0;} }
// @include clearfix;

@mixin pod-block-100 {
		margin: 0 auto; position: relative; box-sizing: content-box;
		
			// Для телефонов
			@include respond-to(handhelds) { width: 100%;  }
			// Для телефонов с широким экраном
			@include respond-to(wide-handhelds) { width: 100%;  }
			// Для маленьких планшетов
			@include respond-to(small-tablets) { width: 100%;  }
			// Для планшетов
			@include respond-to(tablets) { width: 100%;  }
			// Для экранов
			@include respond-to(display) {max-width:1200px;  }

}

// @include pod-block-100;


@mixin pod-block-adaptive {



 		min-height: 50px;
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: top;
        zoom: 1;
        *display: inline;
        _height: 50px;
		
	
}
// @include pod-block-adaptive;


// миксин для стилизации начала списков
@mixin counter($var){
	counter-reset: $var;
	> li, > dt{
		&:before{
			content: counter($var);
			content-increment: $var;
		}
	}
}
// @include counter;



//миксин для кнопок
@mixin btn{
 	float: right; margin-right: 15px; background: url(../img/euro_button.png); background-size:cover;color: #fff; border: none; border-radius: 6px; 			    	   
		&:hover{ background: #FC0808 ; background-size:cover;}				    	
}

// @include btn;


//миксин для плавной анимации

@mixin hover-anim {
&:hover{
	transition: all 0.6s;
	-moz-transition: all 0.6s;
	-webkit-transition: all 0.6s;
	-o-transition: all 0.6s;
}			

}


// @include hover-anim;




@import "reset";
@import "header";
@import "main_menu-2";
@import "left_content";
@import "right_content";
@import "owl_carousel";
@import "footer";
@import "price-table";
@import "uslugi";
@import "about";
@import "production";
@import "luki-pod-plitku";
@import "luki-pod-pokrasku";
@import "napolni-luki";
@import "individualni-luki";
@import "model-euro-m";
@import "modal-window";/*надо настроить*/
@import "kontacty";
@import "photo";
@import "photo-galery";
@import "video-galery";/*надо настроить*/
@import "news";





.left{ float: left; }
.right{ float: right;}
.mp-o{ margin: 2px 0; padding: 2px 0;}
.center{ text-align: center;}

.block {
	width: 100%; box-sizing: content-box; 
	.pod-block { 

		 margin: 0 auto; position: relative; box-sizing: content-box;
		// Для телефонов
		@include respond-to(handhelds) { width: 100%; };
		// Для телефонов с широким экраном
		@include respond-to(wide-handhelds) { width: 100%; };
		// Для планшетов
		@include respond-to(small-tablets) { width: 100%; };
		@include respond-to(tablets) { width: 100%; };
		// Для экранов
		@include respond-to(display) { max-width: 1350px; };
		
	}
}

.left-content {width: 29%; float: left;
			.padding { padding: 2% 1% 0% 3%; }

// Для телефонов до 480
	@include respond-to(handhelds) { float: none; width: 100%; };
	// Для телефонов с широким экраном до 767
	@include respond-to(wide-handhelds) { float: none; width: 100%; };
	// Для планшетов до 979
	@include respond-to(tablets) {  };
	// Для экранов от 980
	@include respond-to(display) {  };
		}


.right-content {width: 69%; float: right;
.padding { padding: 2% 0% 0% 1%;}
			// Для телефонов до 480
			@include respond-to(handhelds) { float: none; width: 100%; };
			// Для телефонов с широким экраном до 767
			@include respond-to(wide-handhelds) { float: none; width: 100%; };


p {line-height: 130%;}

}






span.skype_c2c_container img.skype_c2c_logo_img {display: none!important;}




//Градиентный баграунд контента
#bg-body{
	background: -webkit-linear-gradient(top, rgb(250, 250, 250) 00%, rgb(189, 215, 240) 55%, rgb(164, 186, 209) 99%);
	background: -moz-linear-gradient(top, rgb(250, 250, 250) 00%, rgb(189, 215, 240) 55%, rgb(164, 186, 209) 99%);
    background: -o-linear-gradient(top, rgb(250, 250, 250) 00%, rgb(189, 215, 240) 55%, rgb(164, 186, 209) 99%);
    background: -ms-linear-gradient(top, rgb(250, 250, 250) 00%, rgb(189, 215, 240) 55%, rgb(164, 186, 209) 99%);
    background: linear-gradient(to bottom, rgb(250, 250, 250) 00%, rgb(189, 215, 240) 55%, rgb(164, 186, 209) 99%);
}



// сделал див с ид для отмены флоатов, потому что не понял куда 
// вставлять @include clearfix;
.clear{clear: both;}
				



h1.title{ color: #032447; text-align: center; text-transform: uppercase; font-size: 20px; padding: 15px 10px; margin: 15px 0;}

h2.title{ color: #032447; text-align: center; text-transform: uppercase; font-size: 18px; padding: 15px 10px; margin: 15px 0;}

	h3.left-news {

    font-size: 16px;
    color: #292929;
    font-weight: bold;
    line-height: 130%;
    text-align: center;
    text-transform: none;
    margin-top:10px;
    background-color: none;

	}

.blue-line{ border-bottom: 1px solid #0476C3; }

h2.blue-color{ color: #0476C3;  text-align: center; margin: 15px 0; font-weight: 100;}

.line{ height:3px; width: 100%; background: #ACACAC; margin:0 0 5px 0;}




//gallery index, view

.gallery-slider {


				    border-top: 1px solid #ACACAC;
			    border-left: 2px solid #ACACAC;
			    border-right: 2px solid #ACACAC;
			    border-bottom: 4px solid #ACACAC;



.prev-btnOFF{
	position: absolute;
	top: 43%;
	left: 2%;
	padding: 25px;
	z-index: 9999;
	background: url('../img/prev.png') no-repeat 100% 100%;
	background-size: cover;
	cursor: pointer;
	box-sizing: border-box;
	
	@include respond-to(small-tablets){
		padding: 15px;
	}
	@include respond-to(wide-handhelds){
		display: none;
	}
}
.next-btnOFF{
	position: absolute;
	top: 43%;
	right: 2%;
	padding: 25px;
	z-index: 9999;
	background: url('../img/next.png') no-repeat 100% 100%;
	background-size: cover;
	cursor: pointer;
	box-sizing: border-box;
	
	@include respond-to(small-tablets){
		padding: 15px;
	}
	@include respond-to(wide-handhelds){
		display: none;
	}
}



}


.gallery-slider{
	width: 95%;
	margin: 10px auto;
}
.gallery-slider2{
	width: 100%;
	margin: 10px auto;
}
.gallery-slider2 img{
	width: 100%;
}
.gallery-slider-main{
	width: 100%;
	margin: 0 auto;
	background:#e6e7e7;

	.slider-for-main{
	max-width: 1200px;
	margin: 0 auto;

	}

}
.gallery-slider-main img{
	width: 100%;
}
.slider-nav2, .slider-nav-main{
	display: none;
}
.slider-nav{
	padding: 0 80px;
	background: linear-gradient(to bottom, #fff, #D0D0D0);
	
	@include respond-to(handhelds){
		padding: 0;
	}
	@include respond-to(wide-handhelds){
		padding: 0;
	}
}
.slider-nav img{
	margin: 10px;
	padding: 4px;
	border: 1px solid #aaa;
	
	@include respond-to(handhelds){
		margin: 0;
	}
	@include respond-to(wide-handhelds){
		margin: 0;
	}
}

