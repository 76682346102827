#news{ 

	h1.title{ text-align: left;}


	.text-news{
		@include clearfix;

		p{ line-height: 1.55;}


		a.detail-news {

			@include btn;
			padding: 8px 12px; font-size: 15px;
		}

		button{
			@include btn;
			padding: 8px 12px; font-size: 15px;
		}
	}
	
 }// news


